import React, { Component, Fragment } from "react";
import $ from "jquery";
import _ from "lodash";
import { connect } from "react-redux";
import { debounce } from "throttle-debounce";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { Collapse, Card } from "reactstrap";

import AuthService from "../../Auth/AuthService";
import Services from "../../../modules/Permissions/PermissionService";
import mailGunEmailActions from "../../../store/mailgunEmails/action";
import AUX from "../../../hoc/Aux_";

import {
  UpdateEmailFrequency,
  UpdateEmailType,
} from "../Enums/UpdateEmailEnums";

class EmailSubscribersModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //selectedMembers: [],
      isMemberCardOpen: true,
      isTeamCardOpen: false,
      searchTermMember: "",
      searchTermTeam: "",
      MemberObj: {
        SearchTerms: "",
        Offset: 0,
        Fetch: 10,
        Data: [],
        IsMore: true,
        FetchedFirst: true,
      },
      memberId: AuthService.getProfile().memberData.id,

      // For Teams List
      TeamObj: {
        AllData: [],
        SearchTerms: "",
        Offset: 0,
        Fetch: 10,
        Data: [],
        IsMore: true,
        FetchedFirst: true,
      },
    };

    this.searchMember = this.searchMember.bind(this);
    this.searchDebounceMember = debounce(500, this.searchMember);

    this.searchTeam = this.searchTeam.bind(this);
    this.searchDebounceTeam = debounce(500, this.searchTeam);
  }

  handleClose = () => {
    this.props.toggleSelectEmailUpdateSubscribersModal();
    this.setState({
      showAssignMemberModal: false,
    });
  };
  searchMember = () => {
    let MemberDataObj = this.state.MemberObj;
    MemberDataObj.Offset = 0;
    MemberDataObj.Fetch = 10;
    MemberDataObj.SearchTerms = this.state.searchTermMember;
    MemberDataObj.IsMore = true;
    MemberDataObj.FetchedFirst = true;
    this.setState({ MemberObj: MemberDataObj });
  };
  searchTeam = () => {
    let searchTerm = this.state.searchTermTeam;
    var TeamObj = { ...this.state.TeamObj };
    let filteredData = TeamObj.AllData.filter(
      (x) =>
        _.toLower(x.teamName).includes(
          _.toLower(searchTerm.toLowerCase() || "")
        ) ||
        _.toLower(x.ownerName).includes(
          _.toLower(searchTerm.toLowerCase() || "")
        )
    );

    TeamObj.Data = filteredData;
    TeamObj.Offset = 0;
    TeamObj.Fetch = 10;
    if (filteredData.length > 10) {
      TeamObj.IsMore = true;
    } else {
      TeamObj.IsMore = false;
    }

    this.setState({ TeamObj });
  };
  showSpinner = () => {
    $(".loading").show();
  };

  hideSpinner = () => {
    $(".loading").hide();
  };

  handleSelectMember = (e) => {
    let index = e.target.dataset.index;
    index = !index ? e.target.parentElement.dataset.index : index;
    let memberId = this.state.MemberObj.Data[index].id;
    let firstName = this.state.MemberObj.Data[index].firstName;
    let lastName = this.state.MemberObj.Data[index].lastName;
    let facility = this.state.MemberObj.Data[index].facility;
    let region = this.state.MemberObj.Data[index].region;
    let address = this.state.MemberObj.Data[index].email;
    let facilityRegion = `${region}/${facility}`;
    let selectedObj;
    let currentMainObj;
    let duplicateMessage = "";
    ///Handle
    selectedObj = this.props.melstate.selectedEmailUpdateSubscribers;
    currentMainObj = this.props.melstate.emailUpdateSubsMembers;
    duplicateMessage = "Member already exists in list.";
    let isAlreadySelected = selectedObj.some(
      (s) => s.memberId == memberId && s.id == 0
    );
    if (isAlreadySelected) {
      this.props.deSelectEmailUpdateMember(memberId);
      return;
    }
    let id = 0;
    let isDuplicate = currentMainObj.some((s) => s.memberId == memberId);
    let isNonDbItem = currentMainObj.some(
      (x) => x.memberId == memberId && x.id !== 0
    );

    let emailListId =
      this.props.melstate.emailListId || this.props.emailListId || 0;
    let filterEmailListId = emailListId;
    if (isDuplicate && isNonDbItem) {
      toast.warn(duplicateMessage);
    } else {
      const { filterId } = this.props;
      this.props.selectEmailUpdateMember({
        memberId,
        firstName,
        lastName,
        facilityRegion,
        address,
        id, ///primary key ==0
        emailListId: filterEmailListId,
        filterId: filterId,
        updateTypeOption: UpdateEmailType.UpdatesOnly.label,
        updateFrequencyOption: UpdateEmailFrequency.DAILY.label,
        updateTypeId: UpdateEmailType.UpdatesOnly.id,
        updateFrequencyId: UpdateEmailFrequency.DAILY.id,
        isActive: true,
      });
    }
  };
  //Can be used to add additional subscribers and authorized senders
  addUpdateEmailSubscribers = () => {
    this.props.selectMultipleUpdateEmailSubscribers();
    this.handleClose();
  };
  showActiveMemberItem = (item) => {
    let currentObj;

    currentObj = this.props.melstate.selectedEmailUpdateSubscribers;

    var result =
      !_.isEmpty(currentObj) &&
      currentObj.findIndex(
        (x) => x.memberId == item.id && x.id == 0 // just on the client side
      ) != -1;
    return result;
  };
  showDisabledMemberItem = (item) => {
    let currentObj;
    currentObj = this.props.melstate.emailUpdateSubsMembers;
    var result =
      !_.isEmpty(currentObj) &&
      currentObj.findIndex((x) => x.memberId == item.id && x.id !== 0) != -1;

    return result;
  };
  loadMember = () => {
    // let positionIndex = this.state.positionConfIndex;
    if (this.state.MemberObj.FetchedFirst) {
      this.state.MemberObj.FetchedFirst = false;
      this.loadMemberData();
    }
    const { MemberObj } = this.state;
    const MemberData = MemberObj ? MemberObj.Data || [] : [];
    const IsMore = MemberObj ? MemberObj.IsMore : false;

    return (
      <Fragment>
        <p className="text-black-50">
          <b>Hint:</b> <i>Name [Email] Region/Facility</i>
        </p>
        <div id="MemberlistScroll">
          <InfiniteScroll
            dataLength={MemberData.length}
            next={this.loadMemberData}
            hasMore={IsMore}
            loader={<h4>Loading...</h4>}
            scrollableTarget="MemberlistScroll"
          >
            {MemberData.map((item, index) => {
              return (
                <div
                  key={index}
                  className={
                    "c-pointer px-4 border py-2 card-text w-100" +
                    (this.showActiveMemberItem(item)
                      ? " active" // selected
                      : this.showDisabledMemberItem(item)
                      ? " opacity-half" // disabled (having Db generated id)
                      : "")
                  }
                  onClick={this.handleSelectMember}
                  data-index={index}
                >
                  {`${item.lastName ? item.lastName + ", " : ""}${
                    item.firstName
                  } ${
                    item.nickName != null ? '"' + item.nickName + '" ' : ""
                  }`}{" "}
                  {item.email && <b>[{item.email}]</b>}
                  {" - "}
                  {item.region + "/" + item.facility}
                </div>
              );
            })}
          </InfiniteScroll>
        </div>
      </Fragment>
    );
  };
  loadMemberData = async () => {
    let MemberObj = this.state.MemberObj;
    let data = await Services.LoadMembersByScope(
      MemberObj.SearchTerms,
      MemberObj.Offset,
      MemberObj.Fetch
    );
    if (!_.isEmpty(data)) {
      if (data.statusCode == 200) {
        // console.log(data.result.length, "result length:");
        // console.log(this.state.MemberObj.Data.length + 10, "data length");
        if (!_.isEmpty(data.result) && data.result.length > 0) {
          //apiResponse = data.result; ///Removed Non-Members to display in Modal
          this.state.MemberObj.IsMore =
            data.result.length == this.state.MemberObj.Fetch;
          this.state.MemberObj.Data = data.result;

          this.state.MemberObj.Fetch =
            data.result.length == this.state.MemberObj.Fetch
              ? MemberObj.Fetch + 10
              : MemberObj.Fetch;

          this.setState({ MemberObj: this.state.MemberObj });
        } else {
          var mainObj = { ...this.state.MemberObj };
          mainObj.IsMore = false;
          mainObj.Data = data.result;
          mainObj.Fetch = 10;
          this.setState({ MemberObj: mainObj });
        }
      }
    }
  };
  assignMember = () => {
    return (
      <Fragment>
        <Card className="mb-1">
          <h6
            className="card-header bg-primary text-white mb-3 mt-0 cursorPointer"
            onClick={() => {
              this.setState(
                {
                  isMemberCardOpen: !this.state.isMemberCardOpen,
                },
                () => {
                  if (this.state.isTeamCardOpen) {
                    this.setState({
                      isTeamCardOpen: false,
                    });
                  }
                }
              );
            }}
          >
            <i class="far fa-user pr-1"></i>
            Members{" "}
            <i
              className={
                this.state.isMemberCardOpen === false
                  ? "fas arrow-icon fa-chevron-down float-right"
                  : "fas arrow-icon fa-chevron-up float-right"
              }
            ></i>
          </h6>
          <Collapse isOpen={this.state.isMemberCardOpen}>
            <div className="row d-flex justify-content-center">
              <div className="col-lg-8 text-center">
                <form role="search" className="app-search mb-3">
                  <div className="form-group mb-0">
                    <input
                      type="text"
                      className="form-control rounded border mb-1 py-2 w-100"
                      placeholder="Member Search.."
                      onChange={(e) => {
                        this.setState({ searchTermMember: e.target.value });
                        this.searchDebounceMember();
                      }}
                    />
                    <button
                      type="button"
                      className="c-pointer text-secondary bg-transparent border-0"
                    >
                      <i className="fa fa-search" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div id="memberList" className="col-lg-10">
                {this.loadMember()}
              </div>
            </div>
          </Collapse>
        </Card>
      </Fragment>
    );
  };

  buttons = () => {
    let btnText = "";
    btnText = "Done Adding Email Update Subscribers";

    return (
      <Fragment>
        <hr />
        <div className="row pt-3">
          <div className="col-sm-10 offset-sm-2 col-12">
            <div className="text-right">
              <button
                className="btn btn-danger btn-x-sm btn-sm w-md waves-effect waves-light"
                type="button"
                onClick={this.handleClose}
              >
                Cancel
              </button>
              <button
                onClick={this.addUpdateEmailSubscribers}
                className="btn btn-success btn-x-sm btn-sm ml-sm-2 ml-1 w-md waves-effect waves-light"
                type="submit"
              >
                {btnText}
              </button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  onShow = () => {
    this.props.removeStandByAuthSender();
  };
  render() {
    let modalHeader = "Select Update Email Subscribers";

    return (
      <AUX>
        <Modal
          id="AssignUpdateEmailSubscribers"
          show={this.props.showModal}
          onHide={this.handleClose}
          size="lg"
          centered
          backdrop="static"
          dialogClassName="modal-45w"
          onShow={this.onShow}
        >
          <Modal.Header className="modal-header bg-secondary py-2" closeButton>
            <Modal.Title>
              <h5 className="modal-title text-uppercase text-white mt-0">
                {" "}
                ASSIGN MEMBER{" "}
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="AuthSenderModal modalshadow pl-5 pr-5">
            <div className="col-12">
              <h4 className="text-primary font-weight-bold changeColor">
                {modalHeader}
              </h4>
            </div>
            {this.assignMember()}
            {this.buttons()}
          </Modal.Body>
        </Modal>
      </AUX>
    );
  }
}

// export default EmailSubscribersModal;

export default connect(
  (state) => ({
    melstate: state.mailGunEmail,
    savedSearchState: state.saveSearch,
  }),
  {
    ...mailGunEmailActions,
  }
)(EmailSubscribersModal);
