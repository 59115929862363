import axios from "../../modules/Shared/auth-header";
import { toast } from "react-toastify";
import $ from "jquery";
import EmailListService from "../../modules/Email/EmailListService";
import ConfigDataType from "../../modules/Email/Enums/ConfigDataType";
import MailingListMembersTables from "../../modules/Email/Enums/MailingListMembersTables";
import AddRecptType from "../../modules/Email/Enums/AddRecptType";
import EmailConfigTabs from "../../modules/Email/Enums/EmailConfigTabs";
import MailGunEmailTypes from "../../modules/Email/Enums/MailGunEmailTypes";
import _ from "lodash";

const actions = {
  //ActionNameConsts
  SET_AUTH_SEND_LOADING: "SET_AUTH_SEND_LOADING",
  SET_UPDATE_EMAIL_SUBS_LOADING: "SET_UPDATE_EMAIL_SUBS_LOADING",

  RESET_AUTHORIZED_SENDERS_DATA: "RESET_AUTHORIZED_SENDERS_DATA",
  SET_AUTHORIZED_SENDERS_DATA: "SET_AUTHORIZED_SENDERS_DATA",
  SET_EMAIL_UPDATE_SUBS_DATA: "SET_EMAIL_UPDATE_SUBS_DATA",

  SET_AUTH_SEND_TOTAL_DATA_COUNT: "SET_AUTH_SEND_TOTAL_DATA_COUNT",
  SET_AUTH_SEND_PAGINATION_INFO: "SET_AUTH_SEND_PAGINATION_INFO",

  SET_SELECTED_AUTH_SENDER: "SET_SELECTED_AUTH_SENDER",
  REMOVE_SELECTED_ENTITY: "REMOVE_SELECTED_ENTITY",
  REMOVE_EMAIL_UPDATE_SUB: "REMOVE_EMAIL_UPDATE_SUB",
  TOGGLE_SELECT_AUTH_SENDERS_MODAL: "TOGGLE_SELECT_AUTH_SENDERS_MODAL",
  TOGGLE_SELECT_EMAIL_UPDATE_SUBS_MODAL:
    "TOGGLE_SELECT_EMAIL_UPDATE_SUBS_MODAL",

  SET_MG_LIST_FULL_ADDRESS: "SET_MG_LIST_FULL_ADDRESS",
  SET_MG_LIST_ALIAS: "SET_MG_LIST_ALIAS",
  SET_MG_LIST_DOMAIN: "SET_MG_LIST_DOMAIN",
  //SET_MG_POST_LIST_DOMAIN: "SET_MG_POST_LIST_DOMAIN",
  SET_MG_LIST_DETAILS: "SET_MG_LIST_DETAILS",
  RESET_MG_LIST_DETAILS: "RESET_MG_LIST_DETAILS",
  SET_PRIVILEGE: "SET_PRIVILEGE",
  SET_COPY_TO_SENDER: "SET_COPY_TO_SENDER",
  SET_REPLY_PREF: "SET_REPLY_PREF",
  SET_LIST_NAME: "SET_LIST_NAME",
  ADD_MULTIPLE_AUTH_SENDERS: "ADD_MULTIPLE_AUTH_SENDERS",
  ADD_MULTIPLE_UPDATE_EMAIL_SUBS: "ADD_MULTIPLE_UPDATE_EMAIL_SUBS",
  SELECT_AUTH_SENDER_MEMBER: "SELECT_AUTH_SENDER_MEMBER",
  SELECT_EMAIL_UPDATE_MEMBER: "SELECT_EMAIL_UPDATE_MEMBER",

  DESELECT_EMAIL_UPDATE_ENTITY: "DESELECT_EMAIL_UPDATE_ENTITY",

  DESELECT_SELECTED_POPUP_ENTITY: "DESELECT_SELECTED_POPUP_ENTITY",
  SELECT_POPUP_TEAM: "SELECT_POPUP_TEAM",
  DESELECT_POPUP_TEAM: "DESELECT_POPUP_TEAM",
  REMOVE_STAND_BY_AUTH_SENDERS: "REMOVE_STAND_BY_AUTH_SENDERS",
  SET_EMAIL_LIST_NAME: "SET_EMAIL_LIST_NAME",
  SET_ADD_RECPT_DATA: "SET_ADD_RECPT_DATA",
  RESET_EMAIL_CONFIG_DETAILS: "RESET_EMAIL_CONFIG_DETAILS",
  //##Additional Recipients Work##//

  TOGGLE_SELECT_ADD_RECPT_MODAL: "TOGGLE_SELECT_ADD_RECPT_MODAL",
  SET_EMAIL_CONFIG_TAB: "SET_EMAIL_CONFIG_TAB",
  SET_NESTED_CONFIG_TAB: "SET_NESTED_CONFIG_TAB",
  SET_FILTER_CONFIG_TAB: "SET_FILTER_CONFIG_TAB",
  ADD_NON_NATCA: "ADD_NON_NATCA",
  SET_ADDTL_SUBS_LOADING: "SET_ADDTL_SUBS_LOADING",
  REMOVE_ADDL_SUBS: "REMOVE_ADDL_SUBS",
  SET_SAVED_SEARCH_DDL_OBJ: "SET_SAVED_SEARCH_DDL_OBJ",
  ////#Non Natca Work//////////////////
  SET_NON_NATCA: "SET_NON_NATCA",
  RESET_NON_NATCA_UI: "RESET_NON_NATCA_UI",
  REMOVE_NON_NATCA: "REMOVE_NON_NATCA",
  SET_ADDTL_MEM_EMAILS: "SET_ADDTL_MEM_EMAILS",

  //  Default Members Work
  SET_DEFAULT_RECPT_DATA: "SET_DEFAULT_RECPT_DATA",
  SET_ALL_DEFAULT_RECPT_DATA: "SET_ALL_DEFAULT_RECPT_DATA",

  SET_IS_EMAIL_LIST_EXISTS: "SET_IS_EMAIL_LIST_EXISTS",
  UPDATE_SUB_BLOCK_FLG: "UPDATE_SUB_BLOCK_FLG",
  SET_DEF_SUBS_LOADING: "SET_DEF_SUBS_LOADING",
  REMOVE_DEFAULT_RECIPIENT: "REMOVE_DEFAULT_RECIPIENT",
  /////////////////////////////////////
  //ActionNameConsts END
  RESET_AUTH_MEMBERS: "RESET_AUTH_MEMBERS",
  RESET_ADDL_MEMBERS: "RESET_ADDL_MEMBERS",
  ///Action Methods
  setAddlSubscribers: (data, type) => (dispatch) => {
    dispatch({
      type: actions.SET_ADD_RECPT_DATA,
      payload: { data, type },
    });
  },
  setDefaultSubscribers: (data) => async (dispatch) => {
    await dispatch({
      type: actions.SET_DEFAULT_RECPT_DATA,
      payload: data,
    });
  },
  setAllDefaultSubscribers: (data) => async (dispatch) => {
    await dispatch({
      type: actions.SET_ALL_DEFAULT_RECPT_DATA,
      payload: data,
    });
  },
  setCurrentEmailConfigTab: (val) => async (dispatch) => {
    await dispatch({
      type: actions.SET_EMAIL_CONFIG_TAB,
      payload: val,
    });
  },
  setCurrentNestedConfigTab: (val) => async (dispatch) => {
    await dispatch({
      type: actions.SET_NESTED_CONFIG_TAB,
      payload: val,
    });
  },
  setCurrentFilterConfigTab: (val) => async (dispatch) => {
    await dispatch({
      type: actions.SET_FILTER_CONFIG_TAB,
      payload: val,
    });
  },
  setEmailListName: (val) => async (dispatch) => {
    await dispatch({
      type: actions.SET_EMAIL_LIST_NAME,
      payload: val,
    });
  },

  // resetAuthSenders: () => (dispatch) => {
  //   dispatch({
  //     type: actions.RESET_MG_LIST_DETAILS,
  //   });
  // },
  resetEmailConfigDetails: () => async (dispatch) => {
    await dispatch({
      type: actions.RESET_EMAIL_CONFIG_DETAILS,
    });
  },
  toggleSelectAuthSendersModal: () => (dispatch) => {
    dispatch({
      type: actions.TOGGLE_SELECT_AUTH_SENDERS_MODAL,
      payload: { val: true },
    });
  },
  toggleSelectEmailUpdateSubscribersModal: () => (dispatch) => {
    dispatch({
      type: actions.TOGGLE_SELECT_EMAIL_UPDATE_SUBS_MODAL,
      payload: { val: true },
    });
  },
  toggleSelectAddRecptModal: () => (dispatch) => {
    dispatch({
      type: actions.TOGGLE_SELECT_ADD_RECPT_MODAL,
      payload: { val: true },
    });
  },
  setReplyPref: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_REPLY_PREF,
      payload: val,
    });
  },
  setPrivilege: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_PRIVILEGE,
      payload: val,
    });
  },
  setCopyToSender: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_COPY_TO_SENDER,
      payload: val,
    });
  },
  getEmailUpdateSubscribers: (filterId) => (dispatch) => {
    dispatch({
      type: actions.SET_UPDATE_EMAIL_SUBS_LOADING,
      payload: { val: true },
    });
    // $(".loading").show();
    axios
      .post("/api/MailGun/GetUpdateEmailSubscribers/", null, {
        params: {
          filterId: filterId,
        },
      })
      .then(({ data }) => {
        debugger;
        if (data && data.message.toLowerCase() == "success") {
          let allEmailUpdateSenders = data.result.emailUpdateSenders || [];
          debugger;
          let response = allEmailUpdateSenders.filter(
            (val) => val.memberId !== null && val.memberId !== undefined
          );
          // if (_.isEmpty(response)) {
          //   return "Empty Data!";
          // }
          dispatch({
            type: actions.SET_EMAIL_UPDATE_SUBS_DATA,
            payload: { data: response },
          });

          dispatch({
            type: actions.SET_UPDATE_EMAIL_SUBS_LOADING,
            payload: { val: false },
          });
        } else {
          toast.error(data.message);
          console.log(data.status);
          dispatch({
            type: actions.SET_UPDATE_EMAIL_SUBS_LOADING,
            payload: { val: false },
          });
        }
        //$(".loading").hide();
      }) ///END of then
      .catch((error) => {
        console.log("error block", error);

        dispatch({
          type: actions.SET_UPDATE_EMAIL_SUBS_LOADING,
          payload: { val: false },
        });
        //$(".loading").hide();
      });
  },
  getAuthSenders: (formData) => (dispatch) => {
    dispatch({
      type: actions.SET_AUTH_SEND_LOADING,
      payload: { val: true },
    });
    // $(".loading").show();
    axios
      .post("/api/MailGun/GetEmailAuthSenders/", null, {
        params: {
          emailEntityType: formData.emailEntityType,
          emailListId: formData.emailListId,
        },
      })
      .then(({ data }) => {
        if (data && data.message.toLowerCase() == "success") {
          let allAuthSenders = data.result.authSenders;
          let memberAuthSenders = allAuthSenders.filter(
            (val) => val.memberId !== null && val.memberId !== undefined
          );
          let teamAuthSenders = allAuthSenders.filter(
            (val) => val.teamId !== null && val.teamId !== undefined
          );
          dispatch({
            type: actions.SET_AUTHORIZED_SENDERS_DATA,
            payload: { data: memberAuthSenders, type: ConfigDataType.Member },
          });
          dispatch({
            type: actions.SET_AUTHORIZED_SENDERS_DATA,
            payload: { data: teamAuthSenders, type: ConfigDataType.Team },
          });
          dispatch({
            type: actions.SET_AUTHORIZED_SENDERS_DATA,
            payload: { data: allAuthSenders, type: ConfigDataType.All },
          });
          dispatch({
            type: actions.SET_AUTH_SEND_LOADING,
            payload: { val: false },
          });
        } else {
          console.log(data.status);
          dispatch({
            type: actions.SET_AUTH_SEND_LOADING,
            payload: { val: false },
          });
        }
        //$(".loading").hide();
      }) ///END of then
      .catch((error) => {
        console.log("error block", error);

        dispatch({
          type: actions.SET_AUTH_SEND_LOADING,
          payload: { val: false },
        });
        //$(".loading").hide();
      });
  },
  selectAuthSender: (authSender, authSenderType) => (dispatch) => {
    dispatch({
      type: actions.SET_SELECTED_AUTH_SENDER,
      payload: { authSender, type: authSenderType },
    });
  },
  selectMultipleAuthSender: (authSenderType) => (dispatch) => {
    dispatch({
      type: actions.ADD_MULTIPLE_AUTH_SENDERS,
      payload: { type: authSenderType },
    });
  },
  selectMultipleUpdateEmailSubscribers: () => (dispatch) => {
    dispatch({
      type: actions.ADD_MULTIPLE_UPDATE_EMAIL_SUBS,
    });
  },
  removeSelectedEntity: (id, type) => (dispatch) => {
    dispatch({
      type: actions.REMOVE_SELECTED_ENTITY,
      payload: { id, type },
    });
  },
  removeEmailUpdateSub: (id) => (dispatch) => {
    dispatch({
      type: actions.REMOVE_EMAIL_UPDATE_SUB,
      payload: { id },
    });
  },
  removeSelectedEntity: (id, type) => (dispatch) => {
    dispatch({
      type: actions.REMOVE_SELECTED_ENTITY,
      payload: { id, type },
    });
  },
  setMgListFullAddress: (address) => async (dispatch) => {
    await dispatch({
      type: actions.SET_MG_LIST_FULL_ADDRESS,
      payload: address,
    });
  },
  setMgListAlias: (alias) => (dispatch) => {
    dispatch({
      type: actions.SET_MG_LIST_ALIAS,
      payload: alias,
    });
  },
  setMgListDomain: (domain) => async (dispatch) => {
    await dispatch({
      type: actions.SET_MG_LIST_DOMAIN,
      payload: domain,
    });
  },
  setEmailListDetails: (emailListDetails) => async (dispatch) => {
    await dispatch({
      type: actions.SET_MG_LIST_DETAILS,
      payload: emailListDetails,
    });
  },
  resetEmailListDetails: () => async (dispatch) => {
    await dispatch({
      type: actions.RESET_MG_LIST_DETAILS,
      payload: null,
    });
  },
  saveEmailConfigData: () => async (dispatch, getState) => {
    let {
      isAliasChanged,
      emailEntityType,
      mgListAddressAlias,
      mgListDomain,
      selectedPrivilige,
      mailingListName,
      replyPreference,
      emailListId,
      authorizedSendersMembers,
      authorizedSendersTeams,
      entityKey,
      additionalRecipientsMembers,
      additionalRecipientsTeams,
      additionalRecipientsNonNatca,
      currentEmailConfigTab,
      mgListAddress,
      sendCopyToSender = false,
      emailUpdateSubsMembers = [],
    } = getState().mailGunEmail;

    let emailAuthorizedSenders = [
      ...authorizedSendersMembers,
      ...authorizedSendersTeams,
    ];

    let emailListAdditionalRecpts = [
      ...additionalRecipientsMembers,
      ...additionalRecipientsTeams,
      ...additionalRecipientsNonNatca,
    ];

    let emailConfigData = {
      emailAuthorizedSenders,
      emailListAdditionalRecpts,
      additionalRecipientsTeams, // Work for maintaing all Additional Team Recipients,
      emailUpdateSubsMembers,
    };
    ///Email List which are not saved search
    if (emailEntityType < MailGunEmailTypes.Member) {
      $(".loading").show();
      let url = "";
      let newEmailAddress;
      //Email types other than Saved search
      if (!isAliasChanged) {
        url = `/api/FacilityRegionEmail/UpdateEmailPrivilege?id=${entityKey}&accessLevel=${selectedPrivilige}&reply_preference=${replyPreference}&name=${mailingListName}&entityType=${emailEntityType}&copyToSender=${sendCopyToSender}`;
      } else {
        let newEmailAlias = mgListAddressAlias;
        newEmailAddress = `${newEmailAlias}@${mgListDomain}`;
        url = `/api/FacilityRegionEmail/UpdateEmailPrivilege?id=${entityKey}&accessLevel=${selectedPrivilige}&reply_preference=${replyPreference}&name=${mailingListName}&newEmailAliasAddress=${newEmailAddress}&newEmailAlias=${newEmailAlias}&entityType=${emailEntityType}&copyToSender=${sendCopyToSender}`;
      }
      await axios
        .post(url, emailConfigData)
        .then(async ({ data }) => {
          if (data.status && data.status.toLowerCase() == "success") {
            //this.hideSpinner();
            toast.success(data.message);
            if (isAliasChanged) {
              await dispatch(actions.setMgListFullAddress(newEmailAddress));
            }
            //Means we are on Manage Subscribers Modal
            if (currentEmailConfigTab == EmailConfigTabs.Subscribers) {
              setTimeout(async () => {
                dispatch({
                  type: actions.SET_ADDTL_SUBS_LOADING,
                  payload: true,
                });
                await dispatch(actions.reloadAdditionalSubsribersTables());
                dispatch({
                  type: actions.SET_ADDTL_SUBS_LOADING,
                  payload: false,
                });
              }, 7000);
            }
            // Means we are on New "Email List" tab
            else if (currentEmailConfigTab == EmailConfigTabs.Settings) {
              ///If edit filter modal closes then whats the point of reloading
              if (selectedPrivilige == "readonly") {
                ///Reload Auth Senders Records
                var reloadAuthSenders = actions.getAuthSenders({
                  emailEntityType,
                  emailListId,
                });
                dispatch(reloadAuthSenders);
              }
            }
            $(".loading").hide();
            return data;
          } else {
            $(".loading").hide();
            toast.error(data.message);
            return null;
          }
        })
        .catch((error) => {
          console.log("error block", error);
          $(".loading").hide();
        });
    }
    ///##########################Save For Email Lists Related to Saved Searches###############################
    else {
      //let { returnResultNo } = getState().advancedFilter;

      let emailListbody = {
        domain_id: 1,
        //email_address: emailAddress + Constants.DEFAULT_DOMAIN,
        // email_address: emailAddress + this.state.domain,
        saved_search_id: entityKey, ///this.props.savedSearchId,
        search_email_privilege_id:
          selectedPrivilige === "readonly"
            ? 1
            : selectedPrivilige === "members"
            ? 2
            : 3,
        email_address: isAliasChanged
          ? `${mgListAddressAlias}@${mgListDomain}`
          : mgListAddress,

        //email_list_title: emailListTitle,
        //reply_preference: this.state.reply_preference,

        email_list_title: mailingListName,
        reply_preference: replyPreference,

        email_alias: mgListAddressAlias,
        copy_to_sender: sendCopyToSender,
        selectedPrivilige: selectedPrivilige,
        isAliasChanged,
        emailAuthorizedSenders: emailAuthorizedSenders || [],
        additionalRecipientsTeams: additionalRecipientsTeams || [], // Work for maintaing all Additional Team Recipients
        savedSearchEmailUpdateSubs: emailUpdateSubsMembers,
      };
      $(".loading").show();
      //*************************Same API with both Add and Update for Filters***************************
      await axios
        .post("/api/SavedSearch/SaveEmailListDetails", emailListbody)
        .then(async ({ data: res }) => {
          if (res.status.toLowerCase() === "success") {
            //// Creating and Adding Members to MailGun
            const emailListId = res.emailListId;
            const entityKey = res.saved_search_id;
            const responseMessage = res.message;
            const url = "/api/MailGun/AddMembersToList";
            // const _data = this.state.savedSearchId;
            const body = {
              searchId: res.saved_search_id, //this.props.savedSearchId
              J_membersaddress: 0,
              address: isAliasChanged
                ? `${mgListAddressAlias}@${mgListDomain}`
                : mgListAddress,
              memberName: 0,
              Description: "",
              name: mailingListName,
              //###(request.AddParameter("list", model.ListName, ParameterType.UrlSegment)####
              ListName: isAliasChanged
                ? `${mgListAddressAlias}@${mgListDomain}`
                : mgListAddress,
              access_level: selectedPrivilige,
              totalrecords: 0,

              emailListId: emailListId, //this.props.emailListId,
              reply_preference: replyPreference,
              emailListAdditionalRecpts: emailListAdditionalRecpts || [],
            };
            await axios.post(url, body).then(async ({ data: res }) => {
              if (res.status.toLowerCase() === "success") {
                $(".loading").hide();

                if (responseMessage == "Email list saved successfully.") {
                  toast.success(
                    "Mailing list being created, please check back in a few minutes."
                  );
                } else {
                  toast.success(res.message);
                }
                ///this.props.onSave(emailListId, entityKey);
                $(".loading").hide();
                if (currentEmailConfigTab == EmailConfigTabs.Subscribers) {
                  //Don't reload instanly it will take some time to additonal team members of the list in the Edit Filter Modal
                  //dispatch({
                  //  type: actions.SET_ADDTL_SUBS_LOADING,
                  //  payload: true,
                  //});
                  //await dispatch(actions.reloadAdditionalSubsribersTables());
                  //dispatch({
                  //  type: actions.SET_ADDTL_SUBS_LOADING,
                  //  payload: false,
                  //});
                } else if (currentEmailConfigTab == EmailConfigTabs.Settings) {
                  if (selectedPrivilige == "readonly") {
                    ///Reload Auth Senders Records
                    var reloadAuthSenders = actions.getAuthSenders({
                      emailEntityType,
                      emailListId,
                    });
                    dispatch(reloadAuthSenders);
                  }
                }
                return res;
                // this.onModalExited();
              } else {
                toast.error(res.message);
                $(".loading").hide();
                return null;
              }
            });
          } else {
            toast.error(res.message);
            $(".loading").hide();
            return null;
          }
        });
    }
  },
  deleteAllAuthorizedListClick: (
    emailListId,
    type,
    currentAccessLevel,
    isAdminAccessLevelChanged,
    emailAuthorizedSenders,
    refreshLogs
  ) => (dispatch) => {
    let body = { emailAuthorizedSenders };
    let url = `/api/Email/DeleteAuthorizedSenderList?emailListId=${emailListId}&type=${type}&currentAccessLevel=${currentAccessLevel}&isAdminAccessLevelChanged=${isAdminAccessLevelChanged}`;
    $(".loading").show();
    axios
      .post(url, body, {
        params: {
          type: type,
          emailListId: emailListId,
        },
      })
      .then(({ data }) => {
        if (data.status && data.status.toLowerCase() == "success") {
          //this.hideSpinner();
          toast.success(data.message);
          var action = actions.getAuthSenders({
            emailEntityType: type,
            emailListId: emailListId,
          });
          dispatch(action);
          refreshLogs();
        } else {
          toast.error(data.message);
        }
        $(".loading").hide();
      })
      .catch((error) => {
        console.log("error block", error);
        $(".loading").hide();
      });
  },
  deleteAllAddlListClick: (
    emailListId,
    type,
    emailListAdditionalRecpts,
    refreshLogs
  ) => (dispatch) => {
    let url = `/api/MailGun/DeleteAdditionalRecipientsList?emailListId=${emailListId}&type=${type}`;
    $(".loading").show();
    let body = emailListAdditionalRecpts;
    axios
      .post(url, body, {
        // params: {
        //   type: type,
        //   emailListId: emailListId,
        // },
      })
      .then(async ({ data }) => {
        if (data.status && data.status.toLowerCase() == "success") {
          //this.hideSpinner();
          toast.success(data.message);
          dispatch({
            type: actions.SET_ADDTL_SUBS_LOADING,
            payload: true,
          });
          await dispatch(actions.reloadAdditionalSubsribersTables());
          dispatch({
            type: actions.SET_ADDTL_SUBS_LOADING,
            payload: false,
          });
          refreshLogs();
        } else {
          toast.error(data.message);
        }
        $(".loading").hide();
      })
      .catch((error) => {
        console.log("error block", error);
        $(".loading").hide();
        dispatch({
          type: actions.SET_ADDTL_SUBS_LOADING,
          payload: false,
        });
      });
  },
  deleteAllAddlTeamListClick: (emailListId, type) => (dispatch) => {
    let url = `/api/MailGun/DeleteAdditionalTeamRecipientsList?emailListId=${emailListId}&type=${type}`;
    $(".loading").show();
    axios
      .post(url, null, {
        params: {
          type: type,
          emailListId: emailListId,
        },
      })
      .then(async ({ data }) => {
        if (data.status && data.status.toLowerCase() == "success") {
          //this.hideSpinner();
          toast.success(data.message);
          dispatch({
            type: actions.SET_ADDTL_SUBS_LOADING,
            payload: true,
          });
          await dispatch(actions.reloadAdditionalSubsribersTables());
          dispatch({
            type: actions.SET_ADDTL_SUBS_LOADING,
            payload: false,
          });
        } else {
          toast.error(data.message);
        }
        $(".loading").hide();
      })
      .catch((error) => {
        console.log("error block", error);
        $(".loading").hide();
        dispatch({
          type: actions.SET_ADDTL_SUBS_LOADING,
          payload: false,
        });
      });
  },
  selectMember: (item) => (dispatch) => {
    dispatch({
      type: actions.SELECT_AUTH_SENDER_MEMBER,
      payload: { ...item },
    });
  },
  selectEmailUpdateMember: (item) => (dispatch) => {
    dispatch({
      type: actions.SELECT_EMAIL_UPDATE_MEMBER,
      payload: { ...item },
    });
  },
  deSelectMember: (id) => (dispatch) => {
    dispatch({
      type: actions.DESELECT_SELECTED_POPUP_ENTITY,
      payload: id,
    });
  },
  deSelectEmailUpdateMember: (id) => (dispatch) => {
    dispatch({
      type: actions.DESELECT_EMAIL_UPDATE_ENTITY,
      payload: id,
    });
  },
  selectTeam: (item) => (dispatch) => {
    dispatch({
      type: actions.SELECT_POPUP_TEAM,
      payload: { ...item },
    });
  },
  deSelectTeam: (id) => (dispatch) => {
    dispatch({
      type: actions.DESELECT_POPUP_TEAM,
      payload: id,
    });
  },
  removeStandByAuthSender: () => (dispatch) => {
    dispatch({
      type: actions.REMOVE_STAND_BY_AUTH_SENDERS,
    });
  },

  ///not using
  removeAddlSubscriber: (id) => (dispatch) => {
    dispatch({
      type: actions.REMOVE_ADDL_SUBS,
    });
    EmailListService.RemoveAuthSender(id);
  },

  reloadAdditionalSubsribersTables: () => async (dispatch, getState) => {
    // let subSearchText = "";
    let subscribersTablesTypes = [
      /// MailingListMembersTables.DefaultSubscribers,
      MailingListMembersTables.AdditionalMemberRecipients,
      MailingListMembersTables.AdditionalTeamRecipients,
      MailingListMembersTables.AdditionalNonNatcaRecipients,
    ];
    let { mgListAddress } = getState().mailGunEmail;
    let params = {
      ///...pagingDetail,
      //subSearchText,
      subscribersTablesTypes,
      mgListAddress: mgListAddress,
    };

    await EmailListService.updateSubscriberList(params).then(
      ({ data: resp }) => {
        // $(".loading").hide();
        if (resp.success) {
          dispatch(actions.setAddlSubsTablesData(resp.data));
          //We want to fill the additionalRecipientsRows object also
          dispatch(actions.setAddlSubscribers(null, AddRecptType.All));
        } else {
          toast.error("Something went wrong", resp.message);
        }
      }
    );
  },
  //Work item 1089
  reloadEmailUpdateSubscribers: () => async (dispatch, getState) => {
    ///Implement the call for loading Update Email Subscribers
  },
  reloadDefaultSubsribersTable: () => async (dispatch, getState) => {
    let subscribersTablesTypes = [MailingListMembersTables.DefaultSubscribers];
    let {
      mgListAddress,
      isAliasChanged,
      mgListAddressAlias,
      mgListDomain,
    } = getState().mailGunEmail;
    let fullAddress = "";
    if (isAliasChanged) {
      fullAddress = `${mgListAddressAlias}@${mgListDomain}`;
    } else {
      fullAddress = mgListAddress;
    }
    let params = {
      subscribersTablesTypes,
      mgListAddress: fullAddress,
    };
    await dispatch(actions.setDefaultSubscribers([]));
    await dispatch(actions.setDefaultSubsLoading(true));
    await EmailListService.updateSubscriberList(params).then(
      ({ data: resp }) => {
        // $(".loading").hide();
        if (resp.success) {
          const { memberResultSet } = resp.data[0].data;
          dispatch(actions.setDefaultSubscribers(memberResultSet));
          dispatch(actions.setAllDefaultSubscribers(memberResultSet));
          dispatch(actions.setDefaultSubsLoading(false));
        } else {
          toast.error("Something went wrong.");
          dispatch(actions.setDefaultSubsLoading(false));
        }
      }
    );
  },
  deleteDefaultRecipientMember: (address) => async (dispatch) => {
    dispatch({
      type: actions.REMOVE_DEFAULT_RECIPIENT,
      payload: {
        email: address,
      },
    });
  },
  updateRecipientSubBlockFlag: (
    key,
    type,
    newSubscribedValue,
    newBlockValue,
    isBlockUnBlock
  ) => async (dispatch) => {
    dispatch({
      type: actions.UPDATE_SUB_BLOCK_FLG,
      payload: {
        key,
        type,
        newSubscribedValue,
        newBlockValue,
        isBlockUnBlock,
      },
    });
  },
  reloadAdditionalSubsriberTeams: () => async (dispatch, getState) => {
    let subscribersTablesTypes = [
      MailingListMembersTables.AdditionalTeamRecipients,
    ];
    let { mgListAddress } = getState().mailGunEmail;
    let params = {
      subscribersTablesTypes,
      mgListAddress: mgListAddress,
    };
    await EmailListService.updateSubscriberList(params).then(
      ({ data: resp }) => {
        if (resp.success) {
          dispatch(actions.setAddlSubsTablesData(resp.data));
        } else {
          toast.error("Something went wrong.");
        }
      }
    );
  },
  getAdditionalSubsribersEmailAddresses: (mgListAddress) => async (
    dispatch
  ) => {
    let subscribersTablesTypes = [
      MailingListMembersTables.AdditionalMemberRecipients,
      MailingListMembersTables.AdditionalTeamRecipients,
      MailingListMembersTables.AdditionalNonNatcaRecipients,
    ];

    let params = {
      subscribersTablesTypes,
      mgListAddress: mgListAddress,
    };
    $(".loading").show();
    await EmailListService.getAdditionalSubsribersEmailAddresses(params).then(
      async ({ data: resp }) => {
        if (resp.success) {
          await dispatch({
            type: actions.SET_ADDTL_MEM_EMAILS,
            payload: resp.data,
          });
        } else {
          toast.error("Something went wrong.");
        }
        $(".loading").hide();
      }
    );
  },
  setAddlSubsTablesData: (stateData) => (dispatch) => {
    stateData.forEach((element) => {
      if (element.type == MailingListMembersTables.AdditionalMemberRecipients) {
        let reloadMembers = actions.setAddlSubscribers(
          element.data.memberResultSet || [],
          AddRecptType.Member
        );
        dispatch(reloadMembers);
      } else if (
        element.type == MailingListMembersTables.AdditionalTeamRecipients
      ) {
        let reloadTeams = actions.setAddlSubscribers(
          element.data.teamResultSet || [],
          AddRecptType.Team
        );
        dispatch(reloadTeams);
      } else if (
        element.type == MailingListMembersTables.AdditionalNonNatcaRecipients
      ) {
        let reloadNonNatcas = actions.setAddlSubscribers(
          element.data.memberResultSet || [],
          AddRecptType.NonNatca
        );
        dispatch(reloadNonNatcas);
      }
    });
  },
  ////###Non Natca Work ###////
  addSelectedNonNatcaMembers: (values) => (dispatch) => {
    dispatch({
      type: actions.ADD_NON_NATCA,
      payload: values,
    });
  },
  handleNonNatcaChange: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_NON_NATCA,
      payload: val,
    });
  },
  resetNonNatcaUI: () => (dispatch) => {
    dispatch({
      type: actions.RESET_NON_NATCA_UI,
    });
  },
  removeSelectedNonNatcaMember: (address) => (dispatch) => {
    dispatch({
      type: actions.REMOVE_NON_NATCA,
      payload: address,
    });
  },
  setAddtlSubsLoading: (val) => async (dispatch) => {
    await dispatch({
      type: actions.SET_ADDTL_SUBS_LOADING,
      payload: val,
    });
  },
  setDefaultSubsLoading: (val) => async (dispatch) => {
    await dispatch({
      type: actions.SET_DEF_SUBS_LOADING,
      payload: val,
    });
  },
  resetAuthSenderMembers: () => (dispatch) => {
    dispatch({
      type: actions.RESET_AUTH_MEMBERS,
    });
  },
  resetAddlMembers: () => (dispatch) => {
    dispatch({
      type: actions.RESET_ADDL_MEMBERS,
    });
  },
  setSavedSearchDDLObject: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_SAVED_SEARCH_DDL_OBJ,
      payload: val,
    });
  },
  sendSupportEmailListRequest: (params) => async (dispatch) => {
    $(".loading").show();
    EmailListService.sendSupportEmailListRequest(params).then(
      async ({ data: resp }) => {
        if (resp.status == "success") {
          toast.success(resp.message);
        } else {
          toast.error("Something went wrong.");
        }
        $(".loading").hide();
      }
    );
  },
  isEmailListExists: (val) => (dispatch) => {
    dispatch({
      type: actions.SET_IS_EMAIL_LIST_EXISTS,
      payload: val,
    });
  },
};

export default actions;
