import { connect } from "react-redux";
import FETrackerActions from "../../store/feTracker/action";
import React, { Component } from "react";
import FEService from "../F&E/F&ERequests/FEService";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import _ from "lodash";
import {
  feequipmentstatuses,
  fe_request_statuses,
} from "../F&E/AdvanceFilter/FE_AdvanceSearch_Modal";
import FE_Tabs_Type from "../F&E/FE_Tabs";
import { toast } from "react-toastify";
import FE_Tabs from "../../modules/F&E/FE_Tabs";
import FE_API_Actions from "../../modules/F&E/FE_API_Actions";
import { isInvalid } from "redux-form";
import FE_AF_Nav_Items from "../../modules/F&E/AdvanceFilter/FE_AF_Nav_Items";
import InventoryStatus from "../F&E/InventoryStatus";
import { debounce } from "throttle-debounce";

const filter = createFilterOptions();

class ListFilters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedStatuses: [{ id: 1, status: "Active", checked: false }],
      //selectedBglines: [],
      //selectedYears: [new Date().getFullYear().toString()],
      loadingOptions: false,

      yearOptions: [
        ...new Set([
          ...Array.from(
            new Array(10),
            (val, index) => new Date().getFullYear() - index
          ),
          ...Array.from(
            new Array(10),
            (val, index) => new Date().getFullYear() + index
          ),
        ]),
      ]
        .sort((a, b) => b - a)
        .map(String),
      statusOptions: [
        { id: 1, status: "Active", checked: false },
        { id: 2, status: "Disposed", checked: false },
      ],
      // budgetLinesOptions: [],
    };
  }
  handleOnChangeYears = debounce(500, (value, reason, details) => {
    this.selectYear(value, reason, details);
  });
  componentDidMount = async () => {
    let preloadedDataFound =
      Object.keys(this.props.festate.filterData).length <= 2; // By Default length is two.... 1) searchApplied 2)years
    preloadedDataFound && (await this.fillAFilterOptions());
    // let { fe_equipments_budgetlines = [] } = this.props.festate.filterData;
    // this.setState({
    //   budgetLinesOptions: [
    //     { id: -1, budgetline: "Select All", budgetlineNo: -1 },
    //     ...fe_equipments_budgetlines,
    //   ],
    // });
  };
  fillAFilterOptions = async () => {
    ///Default CheckBox selection of Active Status
    let searchApplied = this.props.festate.filterData.searchApplied.inventories;
    this.setState({ loadingOptions: true });
    var response = await FEService.advanceFilterPreLoadData();
    if (response != null) {
      this.setState({ loadingOptions: false });
      var options = response.data;
      options = {
        ...options,
        ...this.props.festate.filterData,
      };

      let result = _.merge({}, options, {
        fe_equipment_teams: options.feteams,
        fe_request_teams: options.feteams,

        fe_equipment_regions: options.feregions,
        fe_request_regions: options.feregions,

        fe_equipment_naoffices: options.fenaoffices,

        fe_request_budgetlines: options.febudgetlines,
        fe_equipments_budgetlines: [
          { id: -1, budgetline: "Select All", budgetlineNo: -1 }, //Dummy option
          ...options.febudgetlines,
        ],
        fe_request_statuses: fe_request_statuses,
        fe_equipment_statuses: feequipmentstatuses,
      });
      this.props.setFilterData(result);
      if (
        this.props.festate.activeSubHeader == FE_Tabs_Type.Inventory &&
        ((searchApplied && searchApplied.length == 0) ||
          _.isEmpty(searchApplied))
      ) {
        await this.props.setFilterStatusActive();
        this.applyDropDownListFilters([FE_AF_Nav_Items.Year]);
      }
      this.filterEquipmentList();
      this.props.getMemberTotalCount(this.props.festate.filterData);
    }
  };
  ///Change Handler for Status
  selectStatus = async (value, reason, details) => {
    // this.setState({
    //   selectedStatuses: value,
    // });
    let { fe_equipment_statuses } = this.props.festate.filterData;
    var allChecked = fe_equipment_statuses.filter((a) =>
      value.some((curVal) => a.status == curVal.status)
    );
    if (!_.isEmpty(allChecked)) {
      fe_equipment_statuses.forEach((element) => {
        if (allChecked.some((s) => s.status == element.status)) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      });
    } else {
      fe_equipment_statuses.forEach((element) => {
        element.checked = false;
      });
    }
    if (reason == "remove-option") {
      let { option } = details || {};
      this.removeFilter(FE_AF_Nav_Items.Status, option.status);
    } else if (reason == "clear") {
      await this.props.resetAdvanceFilterTabStateById(FE_AF_Nav_Items.Status);
      this.filterEquipmentList();
    } else {
      this.props.festate.filterData.fe_equipment_statuses = fe_equipment_statuses;
      this.applyDropDownListFilters([FE_AF_Nav_Items.Status]);
      this.props.setFilterData(this.props.festate.filterData);
      this.filterEquipmentList();
    }
  };
  ///Change Handler for BudgetLine
  selectBgLine = async (value, reason, details) => {
    let { fe_equipments_budgetlines } = this.props.festate.filterData;
    let { option = { id: 0 } } = details || {};
    let isSelectAllOption = option.id == -1;
    //-1 indicates it's event for "Select All"
    if (
      isSelectAllOption &&
      reason == "select-option" &&
      value.some((s) => s.id == -1)
    ) {
      fe_equipments_budgetlines.forEach((element) => {
        element.checked = true;
      });
    } else if (isSelectAllOption && reason == "remove-option") {
      fe_equipments_budgetlines.forEach((element) => {
        element.checked = false;
      });
    } else {
      //Options other than Select-All
      var allChecked = fe_equipments_budgetlines.filter((a) =>
        value.some((b) => a.budgetline == b.budgetline)
      );
      if (!_.isEmpty(allChecked)) {
        fe_equipments_budgetlines.forEach((element) => {
          if (allChecked.some((s) => s.budgetline == element.budgetline)) {
            element.checked = true;
          } else {
            element.checked = false;
          }
        });
      } else {
        fe_equipments_budgetlines.forEach((element) => {
          if (allChecked.some((s) => s.budgetline == element.budgetline)) {
            element.checked = false;
          }
        });
      }
    }

    if (!isSelectAllOption && reason == "remove-option") {
      this.removeFilter(FE_AF_Nav_Items.Budget, option.budgetline); // Remove just one thing like specific tag removal
    } else if (
      reason == "clear" ||
      (isSelectAllOption && reason == "remove-option") /// Invoked when 'Select-ALL' deselected OR Crossed All Options
    ) {
      await this.props.resetAdvanceFilterTabStateById(FE_AF_Nav_Items.Budget); //Reset All Filter
      this.filterEquipmentList();
    } else {
      // Normal Filter Selected like DDL option Marked as Applied
      this.props.festate.filterData.fe_equipments_budgetlines = fe_equipments_budgetlines;
      this.applyDropDownListFilters([FE_AF_Nav_Items.Budget]);
      await this.props.setFilterData(this.props.festate.filterData);
      this.filterEquipmentList();
    }
  };
  ///Change Handler for Year
  selectYear = async (value, reason, details) => {
    let currentVal = value.map(String);
    if (_.isEmpty(currentVal)) {
      currentVal = [new Date().getFullYear().toString()];
    }

    if (reason == "remove-option") {
      let { option } = details || {};
      this.removeFilter(FE_AF_Nav_Items.Year, option);
      await this.props.setYearsFilterData(currentVal);
    } else if (reason == "clear") {
      await this.props.resetAdvanceFilterTabStateById(FE_AF_Nav_Items.Year);
      this.filterEquipmentList();
    } else {
      await this.props.setYearsFilterData(currentVal);
      this.applyDropDownListFilters([FE_AF_Nav_Items.Year]);
      this.filterEquipmentList();
    }
  };
  isAnyFormInvalid(formName) {
    if (isInvalid(formName)(this.props.currentState)) {
      return true;
    } else {
      return false;
    }
  }
  filterEquipmentList() {
    /// Only one form in DropDown Filters
    const Forms = {
      Years: "FE_INV_YearsForm",
    };

    let result = false;
    _.forEach(Forms, (value) => {
      if (this.isAnyFormInvalid(value)) {
        result = true;
      }
    });

    if (result) {
      toast.warn("Please correct the Year Filter fields.");
      return false;
    }
    //this.props.festate.filterData.years = this.props.festate.currentYearFilters;

    const formData = {
      ...this.props.festate.filterData,
      recordsTypeList: [],
      PageNo: 1,
      PageSize: 25,
      SortOrder: "id",
      EventType: FE_API_Actions.AdvanceSearch,
    };

    this.props.getResultRowsForAdvanceFilter(formData);
    this.props.resetPagination(FE_Tabs.Inventory);
  }
  ///************************NEW SEARCH FILTERS WORK************************************** */
  removeFilter = async (removeId, optionText) => {
    var removeIndex = _.findIndex(this.props.getSearchAppliedList(), (o) => {
      return o.searchType === +removeId;
    });
    if (removeIndex !== -1) {
      let currentSearchAplied = this.props.getSearchAppliedList()[removeIndex];
      // If there are only on tag for searchType filter the delete it from node...
      if (
        currentSearchAplied.hasOwnProperty("selectedItems") &&
        currentSearchAplied.selectedItems.length === 1
      ) {
        this.props.getSearchAppliedList()[
          removeIndex
        ].selectedItems = this.props
          .getSearchAppliedList()
          [removeIndex].selectedItems.filter((item) => item !== optionText);

        _.remove(this.props.getSearchAppliedList(), {
          searchType: +removeId,
        });
      } else if (
        currentSearchAplied.hasOwnProperty("selectedItems") &&
        currentSearchAplied.selectedItems.length > 1
      ) {
        this.props.getSearchAppliedList()[
          removeIndex
        ].selectedItems = this.props
          .getSearchAppliedList()
          [removeIndex].selectedItems.filter((item) => item !== optionText);
      }
    }
    if (removeId == FE_AF_Nav_Items.Year) {
      // let { filterData } = this.props.festate;
      // filterData.years = filterData.years.filter((yr) => yr != optionText);
      let { formValues } = this.props.formState;

      if (!_.isEmpty(formValues)) {
        let { FE_INV_YearsForm } = formValues;

        let rightOptions = FE_INV_YearsForm.values.inventoryYear.filter(
          (f) => f !== optionText
        );
        formValues.FE_INV_YearsForm.values.inventoryYear = rightOptions;
      }
    } else {
      this.props.resetAdvanceFilterTabStateById(removeId, optionText);
    }
    await this.props.setFilterData({
      ...this.props.festate.filterData,
    });
    const accountingReviewStatus = this.props.festate.filterData.fe_equipment_statuses.find(
      (f) => f.status == "In Accounting Review"
    );
    var activeSections = [];
    if (
      accountingReviewStatus &&
      this.props.festate.filterData.fe_equipment_statuses
        .filter((f) => f.checked)
        .some((s) => s.id == accountingReviewStatus.id)
    ) {
      activeSections.push(InventoryStatus.InAccReview);
    }

    const formData = {
      ...this.props.festate.filterData,
      recordsTypeList: activeSections,
      PageNo: 1,
      PageSize: 25,
      SortOrder: "id",
      refreshCR: true,
      EventType: FE_API_Actions.AdvanceSearch,
    };
    this.props.getResultRowsForAdvanceFilter(formData);

    this.props.handleTagsVisibility();
  };

  getSelectedOptions = (currentTabKey) => {
    let fdata = this.props.festate.filterData;

    let selectedVals = Object.keys(fdata)
      .filter((it) => it === this.getCurrentTabIdByName(currentTabKey))
      .reduce((obj, key) => {
        ///This condition is for checkbox controls
        if (
          +fdata[key] !== 0 &&
          fdata[key] &&
          fdata[key].constructor === Array
        ) {
          if (
            fdata[key] &&
            +fdata[key] !== 0 &&
            fdata[key].some(
              (s) => typeof s !== "undefined" && s.constructor === Object
            )
          ) {
            obj[key] = fdata[key]
              .filter((it) => it.checked)
              .map((m) => Object.values(m).find((t) => typeof t === "string"));

            return Object.values(obj);
          } else if (fdata[key].some((s) => typeof s === "string")) {
            return Object.values(fdata[key]);
          }
        }
      }, {});

    selectedVals =
      selectedVals && _.size(selectedVals)
        ? selectedVals.filter((f) => typeof f !== "undefined")
        : [];
    let result = [].concat.apply([], selectedVals);

    return result;
  };
  applyDropDownListFilters(dropDownFilters) {
    for (let index = 0; index < dropDownFilters.length; index++) {
      const currentTab = dropDownFilters[index];

      if (currentTab !== 0) {
        var apply = {
          searchType: +currentTab,
          isApplied: true,
          isSearched: true,
        };
        let filterItems;
        if (currentTab == FE_AF_Nav_Items.Year) {
          filterItems = this.props.festate.filterData.years;
        } else {
          filterItems = this.getSelectedOptions(currentTab);
        }
        if (!_.isEmpty(filterItems)) {
          apply.selectedItems = filterItems;

          if (_.isEmpty(this.props.getSearchAppliedList())) {
            this.props.setSearchAppliedList([]);
          }

          var applyIndex = _.findIndex(
            this.props.getSearchAppliedList(),
            (o) => {
              return o.searchType === apply.searchType;
            }
          );
          if (applyIndex === -1) {
            this.props.getSearchAppliedList().push(apply);
          } else {
            this.props.getSearchAppliedList()[applyIndex] = apply;
          }
          this.props.setFilterData({
            ...this.props.festate.filterData,
          });
          this.props.festate.filterData = Object.assign(
            {},
            this.props.festate.filterData,
            {
              activeTab: currentTab,
            }
          );
        }
      }
    }
  }
  getCurrentTabIdByName = (id) => {
    id = parseInt(id);
    let statusesObj = "";
    let budgetsObj = "";

    if (this.props.festate.activeSubHeader === FE_Tabs.Inventory) {
      statusesObj = "fe_equipment_statuses";
      budgetsObj = "fe_equipments_budgetlines";
    }

    let tabName = "";
    switch (id) {
      case FE_AF_Nav_Items.Status:
        tabName = statusesObj;
        break;
      case FE_AF_Nav_Items.Budget:
        tabName = budgetsObj;
        break;
      case FE_AF_Nav_Items.Year: //Years
        tabName = "years";
        break;
      default:
        tabName = "NaN";
        break;
    }

    return tabName;
  };
  ///************************NEW SEARCH FILTERS END************************************** */

  render() {
    //debugger;
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    let {
      filterData: { fe_equipment_statuses: selectedStatuses = [] },
      filterData: { fe_equipment_statuses = [] },
    } = this.props.festate;
    const {
      filterData: {
        years: selectedYears = [new Date().getFullYear().toString()],
      },
    } = this.props.festate;
    const {
      filterData: { fe_equipments_budgetlines = [] },
    } = this.props.festate;
    let selectedBglines = fe_equipments_budgetlines
      ? fe_equipments_budgetlines.filter((f) => f.checked)
      : [];
    selectedStatuses =
      selectedStatuses.length > 0
        ? selectedStatuses.filter((f) => f.checked)
        : [];
    return (
      <>
        <div className="listFilters Ddlfilters">
          <div className="row ">
            <div className="col-4">
              <Autocomplete
                loading={this.state.loadingOptions}
                multiple
                limitTags={2}
                id="statusFilter"
                options={fe_equipment_statuses}
                size="small"
                disableCloseOnSelect
                //filterOptions={(options, state) => options}
                autoHighlight={true}
                getOptionLabel={(option) => {
                  if (option != undefined) {
                    return option.status;
                  } else {
                    return "";
                  }
                }}
                getOptionSelected={(option, value) => option.id === value.id}
                onChange={(e, value, reason, details) => {
                  this.selectStatus(value, reason, details);
                }}
                // For searching options inside the client's array
                filterOptions={(options, params) => {
                  const inputValue = params.inputValue || "";
                  if (inputValue !== "") {
                    //when there is no space in search text
                    if (/^[a-zA-Z-]*$/.test(inputValue)) {
                      //if it is alphabets
                      let result = options.filter((val, ind, arr) => {
                        return val.status
                          .toLowerCase()
                          .includes(inputValue.toLowerCase());
                      });
                      return result;
                    } else if (!isNaN(inputValue) && isFinite(inputValue)) {
                      return [];
                    }
                  }
                  const filtered = filter(options, params);

                  return filtered;
                }}
                value={selectedStatuses}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selectedStatuses.some((s) => s.id == option.id)}
                    />
                    {option.status}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Filter Statuses"
                    placeholder="Select Status"
                  />
                )}
              />{" "}
            </div>
            <div className="col-4 pl-0 pr-0">
              <Autocomplete
                multiple
                loading={this.state.loadingOptions}
                id="bgLineFilter"
                options={fe_equipments_budgetlines}
                size="small"
                disableCloseOnSelect
                //filterOptions={(options, state) => options}
                autoHighlight={true}
                getOptionLabel={(option) => {
                  let optionText = "";
                  if (option != undefined) {
                    if (!_.isEmpty(option.budgetlineNo)) {
                      optionText =
                        option.budgetlineNo + "-" + option.budgetline;
                    } else {
                      optionText = option.budgetline;
                    }
                  }
                  return optionText;
                }}
                getOptionSelected={(option, value) =>
                  option.budgetline == value.budgetline
                }
                limitTags={2}
                onChange={(e, value, reason, details) => {
                  this.selectBgLine(value, reason, details);
                }}
                // For searching options inside the client's array
                filterOptions={(options, params) => {
                  const inputValue = params.inputValue;
                  // const paramsOptionLabel = params.getOptionLabel(
                  //   params.inputValue
                  // );
                  if (inputValue !== "") {
                    if (
                      inputValue.indexOf(" ") > 0 &&
                      /^[a-zA-Z-]*$/.test(inputValue.split(" ")[0]) &&
                      /^[a-zA-Z-]*$/.test(inputValue.split(" ")[1])
                    ) {
                      let firstName = inputValue.split(" ")[0];
                      let lastName = inputValue.split(" ")[1];

                      let result = options.filter((val, ind, arr) => {
                        return (
                          val.approverMember &&
                          val.approverMember.firstname
                            .toLowerCase()
                            .includes(firstName.toLowerCase()) &&
                          val.approverMember &&
                          val.approverMember.lastname
                            .toLowerCase()
                            .includes(lastName.toLowerCase())
                        );
                      });
                      return result;
                    } else if (inputValue.indexOf(" ") < 0) {
                      //when there is no space in search text
                      if (/^[a-zA-Z-]*$/.test(inputValue)) {
                        let result = options.filter((val, ind, arr) => {
                          return (
                            (val.name &&
                              val.name
                                .toLowerCase()
                                .includes(inputValue.toLowerCase())) ||
                            (val.approverMember &&
                              val.approverMember.lastname
                                .toLowerCase()
                                .includes(inputValue.toLowerCase()))
                          );
                        });
                        return result;
                      } else if (!isNaN(inputValue) && isFinite(inputValue)) {
                        let result = options.filter((val, ind, arr) => {
                          return val.budgetlineNo
                            .toString()
                            .includes(inputValue);
                        });
                        return result;
                      }
                    }
                  }
                  const filtered = filter(options, params);

                  return filtered;
                }}
                value={selectedBglines}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={
                        selectedBglines
                          ? selectedBglines.some((s) => s.id == option.id)
                          : false
                      }
                    />
                    {!_.isEmpty(option.budgetlineNo)
                      ? option.budgetlineNo + "-" + option.budgetline
                      : option.budgetline}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Filter BudgetLine"
                    placeholder="Select BudgetLine"
                  />
                )}
              />{" "}
            </div>
            <div className="col-4">
              <Autocomplete
                multiple
                loading={this.state.loadingOptions}
                id="bgLineFilter"
                options={this.state.yearOptions}
                size="small"
                disableCloseOnSelect
                //filterOptions={(options, state) => options}
                autoHighlight={true}
                getOptionLabel={(option) => {
                  return option;
                }}
                onChange={(e, value, reason, details) => {
                  this.handleOnChangeYears(value, reason, details);
                }}
                // For searching options inside the client's array
                filterOptions={(options, params) => {
                  const inputValue = params.inputValue || "";
                  // const paramsOptionLabel = params.getOptionLabel(
                  //   params.inputValue
                  // );
                  if (inputValue !== "") {
                    if (inputValue.indexOf(" ") > 0) {
                      return [];
                    } else if (inputValue.indexOf(" ") < 0) {
                      //when there is no space in search text
                      if (/^[a-zA-Z-]*$/.test(inputValue)) {
                        //if it is alphabets
                        return [];
                      } else if (!isNaN(inputValue) && isFinite(inputValue)) {
                        let result = options.filter((val, ind, arr) => {
                          return val.toString().includes(inputValue);
                        });
                        return result;
                      }
                    }
                  }
                  const filtered = filter(options, params);

                  return filtered;
                }}
                limitTags={2}
                value={selectedYears}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selectedYears.some((s) => s == option)}
                    />
                    {option}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Filter Years"
                    placeholder="Select Year"
                  />
                )}
              />{" "}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(
  (state) => ({ festate: state.fetracker, formState: state.form }),
  {
    ...FETrackerActions,
  }
)(ListFilters);
