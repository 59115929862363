import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import FiltersService from "../modules/AdvanceSearch/SavedSearches/Listing/FiltersService";

///This is used to show all the filter update subscribers
// on the new the screen.
export const getAllEmailUpdateSavedSearches = createAsyncThunk(
  "SavedSearch/GetAllEmailUpdateSavedSearches",
  async () => {
    const response = await FiltersService.getAllEmailUpdateSavedSearches();
    return response;
  }
);
const emailUpdateSlice = createSlice({
  name: "emailUpdateSubscribers",
  initialState: {
    updateSubscribersList: [],
    filteredUpdateSubscribersList: [],
    isFilterUpdateModalOpened: false,
    currentEditFilterUpdateRow: {},
    state: "idle",
    error: null,
  },
  reducers: {
    // Add your non-async reducers here if needed
    setEmailUpdateSubscribers: (state, action) => {
      state.updateSubscribersList = action.payload;
    },
    setFilteredSubscribers: (state, action) => {
      state.filteredUpdateSubscribersList = action.payload;
    },

    toggleEmailUpdateSubscriberModal: (state, action) => {
      const { currentRow, flag } = action.payload;
      state.isFilterUpdateModalOpened = flag;
      state.currentEditFilterUpdateRow = currentRow;
    },
    toggleFilterUpdateFlag: (state, action) => {
      const { id } = action.payload;
      state.filteredUpdateSubscribersList = state.filteredUpdateSubscribersList.map(
        (sub) =>
          sub.filterUpdateId == id ? { ...sub, isActive: !sub.isActive } : sub
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllEmailUpdateSavedSearches.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllEmailUpdateSavedSearches.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.updateSubscribersList = action.payload;
        state.filteredUpdateSubscribersList = action.payload;
      })
      .addCase(getAllEmailUpdateSavedSearches.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  setEmailUpdateSubscribers,
  setFilteredSubscribers,
  toggleEmailUpdateSubscriberModal,
  toggleFilterUpdateFlag,
} = emailUpdateSlice.actions;
export default emailUpdateSlice.reducer;
