import AUX from "../../../hoc/Aux_";
import React, { Component, Fragment } from "react";
import $ from "jquery";
import _ from "lodash";
import { Modal } from "react-bootstrap";
import mailGunEmailActions from "../../../store/mailgunEmails/action";
import savedSearchAction from "../../../store/saveSearch/action";
import { connect } from "react-redux";
import FiltersService from "../../AdvanceSearch/SavedSearches/Listing/FiltersService";
import EmailService from "../../Email/EmailListService";
import { toast } from "react-toastify";
import MailGunEmailTypes from "../../Email/Enums/MailGunEmailTypes";
import AuthService from "../../../modules/Auth/AuthService";
import PermissionFeature from "../../Permissions/PermissionFeature";
import * as emailUpdateActions from "../../../slices/emailUpdateSlice";

import actionActivity from "../../../store/activitylogging/action";
import EmailUpdateSubscribersTable from "../TableData/EmailUpdateSubscribersTable";
import EmailSubscribersModal from "../ModalPopups/EmailSubscribersModal";

class MG_ManageFiltersUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: "member",
      currentEmailConfigTab: "",
      emailPriviliges: [],
      filterDetails: {},
      loadEmailListTab: false,
      entityKey: 0,
      emailListExists: false,
      mailingListAddress: "",
      email_list_title: "",
      emailListId: 0, //Settings ******Mailing list Id******
      emailEntityType: MailGunEmailTypes.Member,
      replyPreference: "",
      listPrivilige: "",
      showDeleteEmailListModal: false,
      isCalledAPI: null,
      isSettingsTabClick: null,
      triggerMapFieldsWithSearchMethod: false,
    };
    this.mailgunConfigurationTabComp = React.createRef();
    this.settingsTabComp = React.createRef();

    this.confirmEmailList = () => {
      this.confirmed = async () => {
        let { emailListId } = this.props.melstate;
        let deleteResponse = null;
        await EmailService.DeleteSSEmailList(emailListId).then(
          ({ data: response }) => {
            $(".loading").hide();
            if (response.status === "success") {
              toast.success("Email list removed successfully!");
              deleteResponse = 1;
              $(".loading").hide();
            } else {
              toast.error(response.message);
              deleteResponse = 0;
              $(".loading").hide();
            }
          }
        );
        this.toggleDeleteEmailListModal();
        if (deleteResponse == 1) {
          ///Email list doesn't exist against Saved Filter
          this.props.isEmailListExists(false);
          this.resetState();
        }
      };

      return this;
    };
  }

  // componentWillReceiveProps(nextProps) {}
  componentDidMount() {}

  resetState = () => {
    this.setState({
      emailListId: 0,
      emailListExists: false,
      entityKey: 0,
      mailingListAddress: "",
      email_list_title: "",
      emailEntityType: MailGunEmailTypes.Member,
      replyPreference: "list",
      listPrivilige: "member",
      filterDetails: {},
      isCalledAPI: null,
      isSettingsTabClick: null,
    });
  };
  showSpinner = () => {
    $(".loading").show();
  };

  hideSpinner = () => {
    $(".loading").hide();
  };
  handleClose = () => {
    ///////////////////Reset the Email Settings Details/////////////////////////
    // this.props.resetEmailListDetails();
    this.props.handleClose();
    // this.props.resetSavedSearch();
    // this.props.setIsAdvancedFilterOpenedInTab(false);
    // this.resetState();
  };

  toggleConfirmDeleteModal = (id) => {
    this.setState({
      isConfirmModalOpen: !this.state.isConfirmModalOpen,
    });
  };

  //Onshow For New Edit Filter Tabs
  onShow = async () => {
    var filterId = this.props.savedSearchId;
    await this.props.getEmailUpdateSubscribers(filterId);
  };

  onCancelClick = () => {
    this.toggleDeleteAllModal();
    this.setState({ selectedPrivilige: "readonly" });
    this.props.setPrivilege("readonly");
  };

  ///Called only for Email List Tab i.e List Address/Authorized Senders/Reply Pref..... add/update
  handleSubmit = async () => {
    await this.props.saveEmailConfigData();
    let { emailListId, emailEntityType } = this.state;
    this.fetchLogsCardClick(emailListId, emailEntityType);
    // if (data != null) {
    //   this.setState({ emailListExists: true });
    // } else {
    //   console.log("error in creation record.");
    // }
    this.resetState();
    this.handleClose();
  };
  onSaveFilterUpdatesList = async () => {
    const { emailUpdateSubsMembers } = this.props.melState;
    var filterId = this.props.savedSearchId;

    var result = await FiltersService.addModifyFilterUpdateEmailSubscribers(
      filterId,
      emailUpdateSubsMembers
    );
    if (result != null) {
      this.props.getEmailUpdateSubscribers(filterId);
    }
  };
  EmailUpdateTab = () => {
    return (
      <>
        <Fragment>
          {AuthService.canView(PermissionFeature.EmailAuthSendersList) && (
            <div className="row mt-3">
              <div className="col-12">
                {/* //Action buttons div// */}
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <h5 className="cursorPointer">
                      Add Update Subscribers
                      <i
                        className="fa fa-plus-circle text-success"
                        onClick={() => {
                          this.props.toggleSelectEmailUpdateSubscribersModal();
                        }}
                      />
                    </h5>
                  </div>
                </div>

                {/*########################### Email Update Subscrirbers Table ############################ */}
                <EmailUpdateSubscribersTable
                  listData={this.props.melstate.emailUpdateSubsMembers}
                  loading={this.props.melstate.isLoadingEmailUpdateSubscribers}
                  confirmDeleteMember={this.handleConfirmDeleteMember}
                  toggleConfirm={this.toggleConfirmDeleteModal}
                  setCurrentSenderInfo={this.setCurrentSenderInfo}
                  caption={"MEMBERS"}
                  hideActionColumn={false}
                  filterId={this.props.savedSearchId}
                  showEmailIcon={false}
                />
              </div>
            </div>
          )}
          {/* ////BackLog 1089 Work of Update Subscribers//// */}
          {/* //// For Adding Members from Modal Displaying ///// */}
          <EmailSubscribersModal
            showModal={this.props.melstate.openEmailUpdateSubscribersModal}
            emailEntityType={this.props.emailEntityType}
            emailListId={this.props.emailListId}
            filterId={this.props.savedSearchId}
          />
        </Fragment>
      </>
    );
  };
  render() {
    //const Id = `${this.props.emailListId},${this.props.emailEntityType}`;
    const search_name = this.props.savedSearchTitle;

    return (
      <AUX>
        <Modal
          className="modal fade team-style"
          id="EditFilterUpdateModal"
          size="xl"
          backdrop="static"
          show={this.props.showModal}
          centered
          onShow={this.onShow}
          onHide={this.handleClose}
        >
          <Modal.Header className="modal-header bg-secondary py-2" closeButton>
            <Modal.Title>
              <h5 className="modal-title text-white mt-0">
                Edit "{search_name}" Filter Updates
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.EmailUpdateTab()}
            <>
              <div className={`form-group mb-4 row`}>
                <div className="col-12 m-t-20 mx-auto text-center">
                  {
                    <Fragment>
                      <button
                        className="btn btn-primary w-md waves-effect cancelModal waves-light mc"
                        data-dismiss="modal"
                        aria-hidden="true"
                        onClick={this.handleClose}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn-success ml-sm-2 ml-1 w-md waves-effect waves-light"
                        type="submit"
                        onClick={this.onSaveFilterUpdatesList}
                      >
                        Save Changes
                      </button>
                    </Fragment>
                  }
                </div>
              </div>
            </>
          </Modal.Body>
        </Modal>
      </AUX>
    );
  }
}
export default connect(
  (state) => ({
    melstate: state.mailGunEmail,
    activityLoggingState: state.activityLogging,
    savedSearchState: state.saveSearch,
    melState: state.mailGunEmail,
  }),
  {
    ...mailGunEmailActions,
    ...savedSearchAction,
    ...actionActivity,
    ...emailUpdateActions,
  },
  null,
  { forwardRef: true }
)(MG_ManageFiltersUpdate);
