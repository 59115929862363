import axios from "../../../../modules/Shared/auth-header";
import { toast } from "react-toastify";
import $ from "jquery";
// import AuthService from "../../../../modules/Auth/AuthService";
// import PermissionFeature from "../../../../modules/Permissions/PermissionFeature";
// import RemoveActionType from "../../../../modules/Email/Enums/RemoveActionType";

export default {
  //******************Removes both Filter and Email List(If Exists)*********************//
  deleteFilterAPI: (searchId, searchType, memberId) => {
    let deleteUrl =
      "/api/SavedSearch/DeleteSearch?savedSearchId=" +
      searchId +
      "&searchType=" +
      searchType +
      "&memberId=" +
      memberId;
    $(".loading").show();
    return axios.delete(deleteUrl).then(({ data }) => {
      if (data.status) {
        if (data.status.toLowerCase() === "success") {
          ///toast.success(data.message);
          $(".loading").hide();
          return { data: data };
        } else {
          toast.error(data.message);
          $(".loading").hide();
          return null;
        }
      } else {
        toast.error("Search cannot be deleted.");
        $(".loading").hide();
        return null;
      }
    });
  },
  getAllSystemFilters: async () => {
    let { data } = await axios
      .get(`/api/SavedSearch/GetAllSavedSearches`)
      .catch((error) => {
        // Handle error
        console.error(error);
      });
    if (data.status.toLowerCase() == "success") {
      return data.data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  getAllEmailUpdateSavedSearches: async () => {
    let { data } = await axios
      .get(`/api/SavedSearch/GetAllEmailUpdateSavedSearches`)
      .catch((error) => {
        // Handle error
        console.error(error);
      });
    if (data.status.toLowerCase() == "success") {
      return data.data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  getMemberSearches: async (memberId) => {
    let { data } = await axios
      .get(`/api/SavedSearch/getMemberSearchesV2/${memberId}`)
      .catch((error) => {
        // Handle error
        console.error(error);
      });
    if (data.status.toLowerCase() == "success") {
      return data.data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  updateSaveSearchName: async (model) => {
    let { data } = await axios
      .post(`/api/SavedSearch/UpdateSavedSearchName`, model)
      .catch((ex) => {
        console.log(ex);
        $(".loading").hide();
      });
    if (data.status === "success") {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
  GetSavedSearchFilter: async (body) => {
    try {
      const response = await axios.post(
        "/api/SavedSearch/GetSavedSearchFilter",
        body
      );
      const { status, data = {} } = response;

      ///debugger;
      if (data.status === "success") {
        return data;
      } else {
        toast.error(data.message);
        return null;
      }
    } catch (error) {
      if (error) {
        if (error.status === 400) {
          // Handle the 400 Bad Request error here
          console.error("400 Bad Request:", error.data);
          toast.error(error.data);
          return null;
        } else {
          // Handle other response errors here
          console.error("Response Error:", error.status, error.data);
          toast.error(error.data);
          return null;
        }
      } else {
        console.error("Network Error or Other Error:", error.message);
        return null;
      }
    }
  },
  togglePin: async (searchId, searchTypeId, memberId) => {
    let patchUrl =
      "/api/SavedSearch/ToggleSearchPin?savedSearchId=" +
      searchId +
      "&searchType=" +
      searchTypeId +
      "&memberId=" +
      memberId;

    const response = await axios.patch(patchUrl);
    const { data } = response;
    if (data.status.toLowerCase() === "success") {
      return data;
    } else {
      return null;
    }
  },
  addModifyFilterUpdateEmailSubscribers: async (filterId, formdata) => {
    $(".loading").show();
    let url =
      "/api/SavedSearch/AddModifyFilterUpdateEmailSubscribers?filterId=" +
      filterId;
    var { data } = await axios.post(url, formdata);
    $(".loading").hide();
    if (data.status && data.status.toLowerCase() == "success") {
      return data;
    } else {
      toast.error(data.message);
      return null;
    }
  },
};
