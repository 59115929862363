const createOptions = (obj) =>
  Object.values(obj).map((item) => ({ label: item.label, value: item.label }));

export const UpdateEmailType = Object.freeze({
  UpdatesOnly: { id: 1, label: "Updates Only" },
  MemberList: { id: 2, label: "Member List" },
});

export const UpdateEmailTypeOptions = createOptions(UpdateEmailType);

export const UpdateEmailFormat = Object.freeze({
  HTML: { id: 1, label: "HTML" },
  CSV: { id: 2, label: "CSV" },
  PDF: { id: 3, label: "PDF" },
});

export const UpdateEmailFormatOptions = createOptions(UpdateEmailFormat);

export const UpdateEmailFrequency = Object.freeze({
  DAILY: { id: 1, label: "Daily" },
  WEEKLY: { id: 2, label: "Weekly" },
  MONTHLY: { id: 3, label: "Monthly" },
});

export const UpdateEmailFrequencyOptions = createOptions(UpdateEmailFrequency);

export const EMAIL_UPDATE_SUBSCRIBERS_FIELDS = {
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  FACILITY: "facilityRegion",
  EMAIL: "address",
  FREQUENCY: "updateFrequencyOption",
  UPDATE_TYPE: "updateTypeOption",
  FORMAT_TYPE: "formatTypeOption",
  FORMAT_TYPE_ID: "formatTypeId",
  MEMBER_ID: "memberId",
  ID: "id",
};
