import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Dashboard from "./Dashboard/Dashboard";
import Directory from "./Directory/Directory";
import Login from "./Auth/Login";
import Register from "./Auth/Register";
import Roles from "./Admin/Roles";
import AddRoles from "./Admin/AddRole/AddRoles";
import Positions2 from "./Admin/Positions";
import Positions from "./Permissions/Positions/Positions";
import EmailConfirmation from "../modules/Auth/EmailConfirmation";
import StartSession from "../modules/Auth/StartSession";
import AccountRecovery from "../modules/Auth/AccountRecovery";
import Users from "./Admin/Users/Users";
import AddUser from "./Admin/Users/AddUser";
import EditUser from "./Admin/Users/EditUser";
import ResetPassword from "./Auth/ResetPassword";
import Payfile from "./Admin/Payfile";
import EditArea from "./Directory/Areas/EditArea";
import EditFacility from "./Directory/Facility/EditFacility";
import EditRegion from "./Directory/Region/EditRegion";
import EditPosition from "./Admin/Positions/EditPosition";
import EditTeam from "./Directory/Teams/EditTeam";
import Finance from "./Finance/Finance";
import Teams2 from "./Directory/Teams/Teams";
import TeamDetail from "./Permissions/Teams/TeamDetail";
import Teams from "./Permissions/Teams/Teams";
import AccountConfigs from "../modules/Account/Config";
import AddMember from "../modules/Directory/Teams/AddMember";
import ChangeMember from "./Directory/Teams/ChangeMember";
import Regions from "./Directory/Region/Region";
import Facilities from "./Directory/Facility/Facilities";
/////import SavedSearchEmailList from "./Admin/SavedSearchEmailListOld";
import EmailList from "./Email/EmailList";
import MyTeams from "./Permissions/Teams/MyTeams";
import PACTracker from "./PACTracker/PACTracker";
import ReviewRequest from "./PACTracker/Request/ReviewRequest";
import Candidate from "./PACTracker/Candidate/Candidate";
import ArchivedTeams from "./Permissions/Teams/ArchivedTeams";
import PrivacyPolicy from "./Auth/privacy-policy";
import ForgotUsername from "./Auth/ForgotUsername";
import ReviewScreenPayFileOne from "./Admin/ReviewScreenPayFileOne";
import ImportSummaryTwo from "./Payfile/Two/ImportSummary";
import FinanceImportSummary from "./Payfile/Finance/ImportSummary";
import Inventory from "./F&E/Inventory/Inventory";
import ProtectFERoute from "./F&E/ProtectFERoute";
import FEReviewRequests from "./F&E/F&ERequests/FE_ReviewRequests";
import FE_MyRequests from "./F&E/F&ERequests/FE_MyRequests";
import Grievances from "./Grievances/Grievances";
import ReviewGrievances from "./Grievances/ReviewGrievances";
import BudgetApprovers from "./F&E/BudgetApprover/BudgetApprovers";
import GrievanceCreateForm from "./Grievances/General/GrievanceCreateForm";
import GrievanceDetailsForm from "./Grievances/GrievanceDetailsForm";
import ConfiureMG_Email from "./Email/MainPage/ConfiureMG_Email";
import MemberNumberLookup from "./Auth/MemberNumberLookup";
//import CustomForm from "./Shared/CustomizedForms/CustomForm";
import { QuestionsBuilder } from "./Shared/CustomizedForms/QuestionsBuilder";
import { CustomQuestions } from "./Shared/CustomizedForms/CustomQuestions";
import ForcedResetPassword from "./Account/ForcedResetPassword";
import Registrations from "./Registrations/User/OpenRegistrationsMainPage";
import MyRegistrations from "./Registrations/User/MyRegistrationsMainPage";
import AdminRegistrations from "./Registrations/Admin/RegistrationsListing";
import AdminTemplates from "./Registrations/Admin/TemplatesListing";
import AdminDiscounts from "./Registrations/Admin/DiscountsListing";
import QuestionsMainPage from "./Registrations/Admin/Questions/QuestionsMainPage";
import MasterForm from "./Registrations/User/RegDetails/Wizard/MasterForm";
import SucessfulPayment from "./Payments/SucessfulPayment";
import Registration_RequestsMainPage from "./Registrations/Admin/Requests/Registration_RequestsMainPage";
import TestCheckBoxes from "./Shared/CustomizedForms/TestCheckBoxes";
import SavedSearchFiltersList from "./AdvanceSearch/SavedSearches/Listing/SavedSearchFiltersList";
import AuthService from "./Auth/AuthService";
import PermissionFeature from "./Permissions/PermissionFeature";

import GrievanceListing from "./Grievances/GrievanceListing";
import ManageUpdateEmailList from "./AdvanceSearch/SavedSearches/Listing/ManageUpdateEmailList";
class routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: Math.random(),
    };
    this.user = AuthService.getProfile().memberData;
  }
  refresh() {
    this.setState({
      key: Math.random(),
    });
  }

  render() {
    return (
      <Switch>
        <Route path="/startSession" component={StartSession} />
        <Route path="/forcedResetPassword" component={ForcedResetPassword} />

        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/ForgotUsername.aspx" component={ForgotUsername} />
        <Route path="/confirmEmail" component={EmailConfirmation} />
        <Route path="/MemberNumberLookup" component={MemberNumberLookup} />
        <Route path="/resetpassword" component={ResetPassword} />

        <Route path="/AccountRecovery" component={AccountRecovery} />

        <Route path="/finance" component={Finance} />

        <Route path="/admin/email-list/view/:id" component={ConfiureMG_Email} />

        <Route
          key={this.state.key}
          exact
          path="/directory"
          render={(props) => <Directory {...props} />}
        />

        <Route
          exact
          strict
          path="/directory/region"
          render={(props) => (
            <Directory {...props} tab={Regions} name="Region" />
          )}
        />
        <Route
          exact
          strict
          path="/directory/region/view/:id"
          component={EditRegion}
        />

        <Route
          exact
          path="/directory/facility"
          render={(props) => (
            <Directory {...props} tab={Facilities} name="Facility" />
          )}
        />
        <Route
          path="/account/changePassword"
          component={AccountConfigs.ChangePassword}
        />
        <Route
          exact
          path="/directory/members"
          render={(props) => (
            <Directory {...props} tab={Users} name="Members" />
          )}
        />
        <Route
          exact
          path="/directory/manageFilterUpdates"
          render={(props) => (
            <Directory
              {...props}
              loadAllFilters={false}
              tab={ManageUpdateEmailList}
              name="FilterUpdates"
            />
          )}
        />
        <Route
          exact
          path="/directory/myfilters"
          render={(props) => (
            <Directory
              {...props}
              loadAllFilters={false}
              tab={SavedSearchFiltersList}
              name="Filters"
            />
          )}
        />
        <Route
          exact
          path="/admin/allfilters"
          render={(props) => {
            if (
              AuthService.canView(PermissionFeature.FilterAdmin) &&
              (this.user.membertypeid == 6 ||
                this.user.membertypeid == 8 ||
                this.user.membertypeid == 10)
            ) {
              return (
                <Directory
                  {...props}
                  loadAllFilters={true}
                  tab={SavedSearchFiltersList}
                  name="Filters"
                />
              );
            } else {
              return (
                <Redirect
                  to={{
                    pathname: "/directory/myfilters",
                    state: { permissionFailed: true },
                  }}
                />
              );
              // Redirect to a different route when the conditions are not met
            }
          }}
        />
        {/* <Route
          exact
          path="/filters/savedsearches"
          component={SavedSearchFiltersList}
        /> */}
        <Route exact strict path="/admin/roles" component={Roles} />
        <Route exact path="/admin/roles/add-roles" component={AddRoles} />
        <Route exact path="/admin/positions" component={Positions2} />
        <Route exact path="/permissions/positions" component={Positions} />
        <Route path="/admin/positions/edit-position" component={EditPosition} />

        <Route path="/admin/uploads" component={Payfile} />
        <Route path="/payfile/summarytwo/:id" component={ImportSummaryTwo} />
        <Route path="/financefile/:id" component={FinanceImportSummary} />
        <Route
          path="/emailLists/my"
          render={(props) => (
            <EmailList {...props} loadAllEmails={false} name="My Emails" />
          )}
        />
        <Route
          path="/emailLists/all"
          render={(props) => {
            if (
              AuthService.canView(PermissionFeature.EmailListAdmin) && // Permission Path ===> "Email List"/Admin/View
              (this.user.membertypeid == 6 ||
                this.user.membertypeid == 8 ||
                this.user.membertypeid == 10)
            ) {
              return (
                <EmailList {...props} loadAllEmails={true} name="All Emails" />
              );
            } else {
              return <Redirect to="/emailLists/my" />;
            }
          }}
        />
        {/* <Route path="/admin/email-list" component={SavedSearchEmailList} /> */}
        <Route
          path="/directory/saved-search/:savedSearchId"
          render={(props) => (
            <Directory {...props} tab={Users} name="Members" />
          )}
        />

        <Route path="/directory/AddNewUser" component={AddUser} />
        <Route
          path="/directory/userAction/:id"
          component={(props) => (
            <EditUser key={props.match.params.id} {...props} />
          )}
        />

        <Route path="/dashboard/myProfile/:id" component={EditUser} />

        <Route path="/account/profile" component={AccountConfigs.Profile} />

        <Route exact path="/PACTracker" component={PACTracker} />
        <Route
          exact
          path="/PACTracker/reviewRequest"
          component={ReviewRequest}
        />
        <Route exact path="/PACTracker/candidate" component={Candidate} />
        {/*<Route exact path="/Grievance" component={GrievanceListing} />*/}
        <Route exact path="/Grievances" component={Grievances} />
        <Route
          exact
          path="/grievances/reviewgrievances"
          component={ReviewGrievances}
        />
        <Route
          exact
          path="/grievances/addgrievance"
          component={GrievanceCreateForm}
        />
        <Route
          key={`${this.state.key}teams`}
          exact
          path="/permissions/teams"
          component={Teams}
        />
        <Route exact path="/grievances/:Id" component={GrievanceDetailsForm} />
        <Route
          exact
          path="/permissions/teams/teamDetail/:Id"
          component={TeamDetail}
        />
        <Route
          exact
          path="/permissions/teams/teamDetail/:Id/:isChild/:parentId"
          component={TeamDetail}
        />
        <Route
          exact
          path="/permissions/teams/archived"
          component={ArchivedTeams}
        />
        <Route exact path="/myTeams" component={MyTeams} />
        <Route
          key={`${this.state.key}teams`}
          exact
          path="/admin/teams"
          component={Teams2}
        />
        <Route exact path="/admin/teams/edit-team" component={EditTeam} />

        <Route path="/teams/add-member" component={AddMember} />
        <Route
          exact
          path="/admin/teams/change-member"
          component={ChangeMember}
        />

        <Route path="/area/edit" component={EditArea} />
        <Route
          exact
          path="/directory/facility/view/:id"
          component={EditFacility}
        />

        <Route
          path="/Uploads/PayFileOneReview/:id"
          component={ReviewScreenPayFileOne}
        />

        <ProtectFERoute
          exact
          path="/FETracker/myRequests"
          component={FE_MyRequests}
        />

        <ProtectFERoute
          exact
          path="/FETracker/inventory"
          component={Inventory}
        />
        <ProtectFERoute
          path="/FETracker/reviewRequest"
          component={FEReviewRequests}
        />
        <ProtectFERoute
          exact
          path="/FETracker/BudgetApprovers"
          component={BudgetApprovers}
        />
        <Route exact path="/FETracker/inventory" component={Inventory} />

        {/*###############Routes For Notification Work in F&E##############*/}
        <Route
          exact
          path="/FETracker/myRequests/:id"
          component={FE_MyRequests}
        />
        <Route
          exact
          path="/FETracker/reviewRequest/:id"
          component={FEReviewRequests}
        />
        <Route exact path="/FETracker/inventory/:id" component={Inventory} />
        {/*###############Routes For Notificaiton Work in F&E###############*/}

        <Route exact path="/TestDynamicForm" component={QuestionsBuilder} />
        <Route exact path="/TestDynamicFormV2" component={CustomQuestions} />
        <Route exact path="/TestCheckBox" component={TestCheckBoxes} />
        <Route exact path="/admin/questions" component={QuestionsMainPage} />
        <Route
          exact
          path="/admin/requests"
          component={Registration_RequestsMainPage}
        />
        <Route exact path="/admin/templates" component={AdminTemplates} />
        <Route exact path="/admin/discounts" component={AdminDiscounts} />

        <Route exact path="/Registrations" component={Registrations} />
        <Route path="/MyRegistrations" component={MyRegistrations} />
        <Route exact path="/MultiStepTest" component={MasterForm} />

        <Route
          exact
          path="/admin/registrations"
          component={AdminRegistrations}
        />
        <Route exact path="/checkout/success" component={SucessfulPayment} />
        <Route path="/" component={Dashboard} />

        <Redirect to="/directory/members" />
      </Switch>
    );
  }
}

export default routes;
