import React, { useState } from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import _ from "lodash";
import overlayFactory from "react-bootstrap-table2-overlay";
import AuthService from "../../../Auth/AuthService";
import TableCustomTotal from "../../../Shared/TableCustomTotal";
import paginationFactory from "react-bootstrap-table2-paginator";
import FilterTablesType from "../../../Email/Enums/FilterTablesType";
import { Spinner } from "reactstrap";
// import "bootstrap4-toggle";
// import "bootstrap4-toggle/css/bootstrap4-toggle.min.css";
const NoDataIndication = () => (
  <div className="container h-100">
    <div className="row NoDataFoundTable">
      <h5>No Data Found!</h5>
    </div>
  </div>
);

const showFilterType = (cell, row) => {
  if (cell == 1) {
    return "Saved Filter";
  } else if (cell == 2) {
    return "Bucket Filter";
  } else {
    // In case a newly Assigned Manager View his MyFilters listing
    let { real_search_type_id = 0 } = row;
    if (real_search_type_id == 1) {
      return "Saved Filter";
    } else {
      return "Bucket Filter";
    }
  }
};
const showTypeWithFormat = (cell, row) => {
  //const sanitizedOption = DOMPurify.sanitize(row.formatTypeOption);
  const str = row.formatTypeOption ? `${cell} (${row.formatTypeOption})` : cell;
  return <div> {str} </div>;
};
const MySearch = (props) => {
  let input;
  const handleClick = () => {
    props.onSearch(input.value);
  };
  const placeHolder =
    props.tableType == FilterTablesType.MyFilters
      ? "Search Filters by Name"
      : "Search Filters by Name/Owner..";
  return (
    <form role="search" className="app-search px-2 my-2">
      <div className="form-group mb-0">
        <input
          ref={(n) => (input = n)}
          type="text"
          id="FiltersKeyword"
          className="form-control rounded border mb-1 py-2 w-100"
          placeholder={placeHolder}
          autoComplete="off"
          onChange={handleClick}
        />

        <button className="c-pointer text-secondary bg-transparent border-0">
          <i className="fa fa-search" />
        </button>
      </div>
    </form>
  );
};
const initToggles = () => {
  window.$("#toggle-enableSub").bootstrapToggle();
};

// ************************Start of the Component************************//
const ManageUpdateEmailTable = (props) => {
  const {
    listData = [],
    isLoading,
    viewFilter,
    toggleSubscribeStatus,
    deleteFilter,
  } = props;
  const handleToggle = (rowId) => {
    toggleSubscribeStatus(rowId);
  };

  initToggles();
  const Actions = (cell, row) => {
    return (
      <div>
        <div className="d-flex align-items-center">
          <i
            className="fas fa-eye fa-md pl-1 pt-2 cursorPointer"
            title="Edit Filter"
            onClick={() => viewFilter(row)}
          ></i>

          <div className="pb-1 pt-1 cursorPointer custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              id={`switch-${row.filterUpdateId}`}
              checked={row.isActive}
              onChange={() => handleToggle(row.filterUpdateId, row.isActive)}
            ></input>
            <label
              class="custom-control-label"
              htmlFor={`switch-${row.filterUpdateId}`}
            >
              {""}
            </label>
          </div>
        </div>
      </div>
    );
  };
  // Extract unique subscribers from listData
  // const subscriberOptions = listData.reduce((acc, curr) => {
  //   if (!acc[curr.updateSubscriberId]) {
  //     acc[curr.updateSubscriberId] = curr.updateSubscriber;
  //   }
  //   return acc;
  // }, {});
  //let qualityFilter;
  const columns = [
    {
      text: "Subscriber",
      dataField: "updateSubscriber",
      sort: true,
      headerClasses: "text-nowrap pl-3 px-1 py-2",
    },
    {
      text: "Filter Name",
      dataField: "filterName",
      sort: true,
      headerClasses: "text-nowrap pl-3 px-1 py-2",
    },
    {
      text: "Type",
      dataField: "filterType",
      sort: true,
      headerClasses: "text-nowrap pl-3 px-1 py-2",
      formatter: (cell, row) => {
        return showFilterType(cell, row);
      },
      searchable: false,
    },
    {
      text: "Freq.",
      dataField: "updateFrequencyOption",
      sort: true,
      headerClasses: "text-nowrap pl-3 px-1 py-2",
    },
    {
      text: "Update Type",
      dataField: "updateTypeOption",
      sort: true,
      headerClasses: "text-nowrap pl-3 px-1 py-2",
      formatter: (cell, row) => {
        return showTypeWithFormat(cell, row);
      },
    },

    // {
    //   text: "Format",
    //   dataField: "formatTypeOption",
    //   sort: true,
    //   headerClasses: "text-nowrap pl-3 px-1 py-2",
    // },
    {
      text: "",
      dataField: "actions",
      isDummyField: true,
      headerClasses: "text-center text-nowrap text-white px-sm-1 py-2",
      headerAttrs: {
        width: "20%",
      },
      formatter: (cell, row) => {
        return Actions(cell, row);
      },
      csvExport: false,
    },
  ];
  const [sizePerPage, setsizePerPage] = useState(10);
  // const handleClick = () => {
  //   qualityFilter(0);
  // };
  const sizePerPageChange = (sizePerPage) => {
    setsizePerPage(sizePerPage);
  };
  const RemotePagination = () => {
    let paginationOptions = {
      totalSize: listData && listData.length,
      paginationTotalRenderer: TableCustomTotal,
      showTotal: true,
      onSizePerPageChange: sizePerPageChange,
      sizePerPage,
    };
    return (
      <>
        <ToolkitProvider keyField="id" data={listData} columns={columns}>
          {(props) => (
            <div>
              <BootstrapTable
                overlay={overlayFactory({
                  spinner: true,
                  styles: {
                    overlay: (base) => ({
                      ...base,
                      background: "rgba(40, 45, 42, 0.19)",
                    }),
                  },
                })}
                noDataIndication={() => {
                  return isLoading === false ? (
                    <NoDataIndication />
                  ) : (
                    <div className="d-flex justify-content-center p-4">
                      <Spinner />
                    </div>
                  );
                }}
                loading={isLoading}
                pagination={paginationFactory({
                  ...paginationOptions,
                })}
                {...props.baseProps}
              />
              <br />
            </div>
          )}
        </ToolkitProvider>
        {/* <input
          id="toggle-enableSub"
          type="checkbox"
          checked
          data-toggle="toggle"
          data-on="<i class='fa fa-bell-o'><i/>Subscriber"
          data-off="<i class='fa fa-bell-slash-o'></i>UnSubscriber"
          data-onstyle="success"
          data-offstyle="danger"
          data-style="android"
          data-width="99"
          data-height="20"
        ></input> */}
      </>
    );
  };
  return RemotePagination();
};

ManageUpdateEmailTable.propTypes = {
  listData: PropTypes.array.isRequired,
};

ManageUpdateEmailTable.defaultProps = {
  listData: [],
};

export default ManageUpdateEmailTable;
