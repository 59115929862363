import ConfigDataType from "../../modules/Email/Enums/ConfigDataType";
import AddRecptType from "../../modules/Email/Enums/AddRecptType";
import actions from "./action";
import EmailConfigTabs from "../../modules/Email/Enums/EmailConfigTabs";
import EmailConfigTables from "../../modules/Email/Enums/EmailConfigTables";

const initialState = {
  selectedMembers: [],
  selectedTeams: [],
  authorizedSendersRows: [],
  authorizedSendersMembers: [],
  authorizedSendersTeams: [],

  selectedEmailUpdateSubscribers: [],
  emailUpdateSubsRows: [],
  emailUpdateSubsMembers: [],
  currentEmailUpdateMembers: [],

  currentAuthorizedMember: {},
  currentAuthorizedTeam: {},

  isLoadingAuthorizedSenders: false,
  isLoadingEmailUpdateSubscribers: false,
  mgListAddressAlias: "",
  mgListDomain: "",
  mgListAddress: "",
  //postListDomain: "members.natca.org",
  openAssignAuthorizedSendersModal: false,
  openEmailUpdateSubscribersModal: false,

  emailListId: 0,
  emailEntityType: 0,
  mgListAddressAliasOld: "",
  selectedPrivilige: "readonly",
  replyPreference: "list",
  mailingListName: "",
  isAliasChanged: false,
  sendCopyToSender: false,
  entityKey: 0,
  //#### Default Members Work########
  defaultRecipientMembers: [],
  allDefaultSubscribers: [],
  //#### Additional Recipients Work ###//
  additionalRecipientsRows: [],
  additionalRecipientsMembers: [],
  additionalRecipientsTeams: [],
  additionalRecipientsNonNatca: [],

  selectedAddtlRecipientsNonNatca: [
    {
      name: "",
      firstName: "",
      lastName: "",
      address: "",
    },
  ],

  currentAddRecptMember: {},
  currentAddRecptTeam: {},

  selectedAddRecptMembers: [],
  selectedAddRecptTeams: [],
  isLoadingAdditionalSubscriber: false,
  isLoadingDefaultSubscriber: false,
  openAssignAdditionalRecipientsModal: false,
  currentEmailConfigTab: "subscribers",
  currentFilterConfigTab: "members",
  currentEmailNestedTab:"emailDetails",

  addtlMemEmails: [],
  savedSearchDllObject: {},
  isEmailListExists: false,
};

export default function mailgunEmails(state = initialState, action) {
  switch (action.type) {
    case actions.SET_DEFAULT_RECPT_DATA: {
      return {
        ...state,
        defaultRecipientMembers: action.payload,
      };
    }
    case actions.SET_ALL_DEFAULT_RECPT_DATA: {
      return {
        ...state,
        allDefaultSubscribers: action.payload,
      };
    }
    case actions.SET_ADDTL_MEM_EMAILS: {
      return {
        ...state,
        addtlMemEmails: action.payload,
      };
    }
    /////////////Start Work for Authorized Senders State/Reducer//////////////
    case actions.SET_EMAIL_CONFIG_TAB: {
      return {
        ...state,
        currentEmailConfigTab: action.payload,
      };
    }
    case actions.SET_NESTED_CONFIG_TAB: {
      return {
        ...state,
        currentEmailNestedTab: action.payload,
      };
    }
    case actions.SET_FILTER_CONFIG_TAB: {
      return {
        ...state,
        currentFilterConfigTab: action.payload,
      };
    }
    case actions.TOGGLE_SELECT_ADD_RECPT_MODAL: {
      return {
        ...state,
        openAssignAdditionalRecipientsModal: !state.openAssignAdditionalRecipientsModal,
        openAssignAuthorizedSendersModal: false,
      };
    }
    case actions.ADD_MULTIPLE_AUTH_SENDERS:
      var actionPayload = action.payload;
      if (actionPayload.type === ConfigDataType.Member) {
        if (state.currentEmailConfigTab == EmailConfigTabs.Subscribers) {
          return {
            ...state,
            additionalRecipientsMembers: [
              ...state.additionalRecipientsMembers,
              ...state.selectedAddRecptMembers.filter((f) => f.id == 0),
            ],
          };
        } else
          return {
            ...state,
            authorizedSendersMembers: [
              ...state.authorizedSendersMembers,
              ...state.selectedMembers.filter((f) => f.id == 0),
            ],
          };
      } else if (actionPayload.type === ConfigDataType.Team) {
        if (state.currentEmailConfigTab == EmailConfigTabs.Subscribers) {
          return {
            ...state,
            additionalRecipientsTeams: [
              ...state.additionalRecipientsTeams,
              ...state.selectedAddRecptTeams.filter((f) => f.id == 0),
            ],
          };
        } else
          return {
            ...state,
            authorizedSendersTeams: [
              ...state.authorizedSendersTeams,
              ...state.selectedTeams.filter((f) => f.id == 0),
            ],
          };
      }

    case actions.ADD_MULTIPLE_UPDATE_EMAIL_SUBS:
      return {
        ...state,
        emailUpdateSubsMembers: [
          ...state.emailUpdateSubsMembers,
          ...state.selectedEmailUpdateSubscribers.filter((f) => f.id == 0),
        ],
      };
    case actions.SELECT_EMAIL_UPDATE_MEMBER:
      return {
        ...state,
        selectedEmailUpdateSubscribers: [
          ...state.selectedEmailUpdateSubscribers,
          action.payload,
        ],
      };

    case actions.SELECT_AUTH_SENDER_MEMBER:
      if (state.currentEmailConfigTab == EmailConfigTabs.Subscribers) {
        return {
          ...state,
          selectedAddRecptMembers: [
            ...state.selectedAddRecptMembers,
            action.payload,
          ],
        };
      } else
        return {
          ...state,
          selectedMembers: [...state.selectedMembers, action.payload],
        };
    case actions.DESELECT_EMAIL_UPDATE_ENTITY:
      return {
        ...state,
        selectedEmailUpdateSubscribers: state.selectedEmailUpdateSubscribers.filter(
          (f) => f.memberId !== action.payload
        ),
        emailUpdateSubsMembers: state.emailUpdateSubsMembers.filter(
          (f) => f.memberId !== action.payload
        ),
      };

    case actions.DESELECT_SELECTED_POPUP_ENTITY:
      if (state.currentEmailConfigTab == EmailConfigTabs.Subscribers) {
        return {
          ...state,
          selectedAddRecptMembers: state.selectedAddRecptMembers.filter(
            (f) => f.memberId !== action.payload
          ),
          additionalRecipientsMembers: state.additionalRecipientsMembers.filter(
            (f) => f.memberId !== action.payload
          ),
        };
      } else {
        return {
          ...state,
          selectedMembers: state.selectedMembers.filter(
            (f) => f.memberId !== action.payload
          ),
          authorizedSendersMembers: state.authorizedSendersMembers.filter(
            (f) => f.memberId !== action.payload
          ),
        };
      }
    case actions.RESET_MG_LIST_DETAILS:
      if (state.currentEmailConfigTab == EmailConfigTabs.Subscribers) {
        return {
          ...state,
          additionalRecipientsRows: [],
          additionalRecipientsTeams: [],
          additionalRecipientsMembers: [],
        };
      } else
        return {
          ...state,
          emailListId: 0,
          emailEntityType: 0,
          entityKey: 0,
          mailingListName: "",
          authorizedSendersRows: [],
          authorizedSendersTeams: [],
          authorizedSendersMembers: [],
          selectedPrivilige: "readonly",
          replyPreference: "list",
          selectedMembers: [],
        };

    case actions.RESET_AUTH_MEMBERS:
      return {
        ...state,
        authorizedSendersRows: [],
        authorizedSendersTeams: [],
        authorizedSendersMembers: [],
      };
    case actions.RESET_ADDL_MEMBERS:
      return {
        ...state,
        additionalRecipientsRows: [],
        additionalRecipientsTeams: [],
        additionalRecipientsMembers: [],
        additionalRecipientsNonNatca: [],
      };
    case actions.RESET_EMAIL_CONFIG_DETAILS:
      return {
        ...state,
        additionalRecipientsRows: [],
        additionalRecipientsTeams: [],
        additionalRecipientsMembers: [],
        emailListId: 0,
        emailEntityType: 0,
        entityKey: 0,
        authorizedSendersRows: [],
        authorizedSendersTeams: [],
        authorizedSendersMembers: [],
        replyPreference: "list",
        selectedPrivilige: "readonly",
        selectedMembers: [],
      };

    case actions.REMOVE_SELECTED_ENTITY:
      var actionPayload = action.payload;
      if (actionPayload.type === ConfigDataType.Member) {
        if (state.currentEmailConfigTab == EmailConfigTabs.Subscribers) {
          return {
            ...state,
            additionalRecipientsMembers: state.additionalRecipientsMembers.filter(
              (el, index) => {
                return el.memberId !== action.payload.id;
              }
            ),
          };
        } else {
          ///Settings Tab Now have Authorized Senders => Members
          return {
            ...state,
            authorizedSendersMembers: state.authorizedSendersMembers.filter(
              (el, index) => {
                return el.memberId !== action.payload.id;
              }
            ),
          };
        }
      } else if (actionPayload.type === ConfigDataType.Team) {
        if (state.currentEmailConfigTab == EmailConfigTabs.Subscribers) {
          return {
            ...state,
            additionalRecipientsTeams: state.additionalRecipientsTeams.filter(
              (el, index) => {
                return el.teamId !== action.payload.id;
              }
            ),
          };
        } else {
          return {
            ...state,
            authorizedSendersTeams: state.authorizedSendersTeams.filter(
              (el, index) => {
                return el.teamId !== action.payload.id;
              }
            ),
          };
        }
      }
    case actions.REMOVE_EMAIL_UPDATE_SUB: {
      return {
        ...state,
        emailUpdateSubsMembers: state.emailUpdateSubsMembers.filter(
          (el, index) => {
            return el.memberId !== action.payload.id;
          }
        ),
      };
    }
    case actions.SET_SELECTED_AUTH_SENDER: {
      var actionPayload = action.payload;

      if (actionPayload.type === ConfigDataType.Member) {
        if (state.currentEmailConfigTab == EmailConfigTabs.Subscribers) {
          return Object.assign({}, state, {
            additionalRecipientsMembers: [
              ...state.additionalRecipientsMembers,
              actionPayload.authSender,
            ],
            currentAddRecptMember: actionPayload.authSender,
          });
        } else
          return Object.assign({}, state, {
            authorizedSendersMembers: [
              ...state.authorizedSendersMembers,
              actionPayload.authSender,
            ],
            currentAuthorizedMember: actionPayload.authSender,
          });
      } else if (actionPayload.type === ConfigDataType.Team) {
        if (state.currentEmailConfigTab == EmailConfigTabs.Subscribers) {
          return Object.assign({}, state, {
            additionalRecipientsTeams: [
              ...state.additionalRecipientsTeams,
              actionPayload.authSender,
            ],
            currentAddRecptMember: actionPayload.authSender,
          });
        } else
          return Object.assign({}, state, {
            authorizedSendersTeams: [
              ...state.authorizedSendersTeams,
              actionPayload.authSender,
            ],
            currentAuthorizedTeam: actionPayload.authSender,
          });
      }
    }

    case actions.TOGGLE_SELECT_AUTH_SENDERS_MODAL: {
      return {
        ...state,
        openAssignAuthorizedSendersModal: !state.openAssignAuthorizedSendersModal,
        openAssignAdditionalRecipientsModal: false,
      };
    }
    case actions.TOGGLE_SELECT_EMAIL_UPDATE_SUBS_MODAL: {
      return {
        ...state,
        openEmailUpdateSubscribersModal: !state.openEmailUpdateSubscribersModal,
      };
    }

    case actions.SET_AUTH_SEND_LOADING: {
      var actionPayload = action.payload;
      return Object.assign({}, state, {
        isLoadingAuthorizedSenders: actionPayload.val,
      });
    }
    case actions.SET_UPDATE_EMAIL_SUBS_LOADING: {
      var actionPayload = action.payload;
      return Object.assign({}, state, {
        isLoadingEmailUpdateSubscribers: actionPayload.val,
      });
    }
    case actions.SET_ADDTL_SUBS_LOADING: {
      var actionPayload = action.payload;
      return Object.assign({}, state, {
        isLoadingAdditionalSubscriber: actionPayload,
      });
    }
    case actions.RESET_AUTHORIZED_SENDERS_DATA:
      if (actionPayload.type === ConfigDataType.Member) {
        if (state.currentEmailConfigTab == EmailConfigTabs.Subscribers) {
          return {
            ...state,
            additionalRecipientsMembers: [],
            isLoadingAdditionalRecipients: false,
          };
        } else
          return {
            ...state,
            authorizedSendersMembers: [],
            isLoadingAuthorizedSenders: false,
          };
      } else if (actionPayload.type === ConfigDataType.Team) {
        if (state.currentEmailConfigTab == EmailConfigTabs.Subscribers) {
          return {
            ...state,
            additionalRecipientsTeams: [],
            isLoadingAdditionalRecipients: false,
          };
        } else
          return {
            ...state,
            authorizedSendersTeams: [],
            isLoadingAuthorizedSenders: false,
          };
      }
    case actions.SET_EMAIL_UPDATE_SUBS_DATA: {
      var actionPayload = action.payload;
      return Object.assign({}, state, {
        emailUpdateSubsMembers: actionPayload.data,
        selectedEmailUpdateSubscribers: actionPayload.data,
      });
    }
    case actions.SET_AUTHORIZED_SENDERS_DATA: {
      var actionPayload = action.payload;
      if (actionPayload.type === ConfigDataType.Member) {
        return Object.assign({}, state, {
          authorizedSendersMembers: actionPayload.data,
          selectedMembers: actionPayload.data,
        });
      } else if (actionPayload.type === ConfigDataType.Team) {
        return Object.assign({}, state, {
          authorizedSendersTeams: actionPayload.data,
          selectedTeams: actionPayload.data,
        });
      } else if (actionPayload.type === ConfigDataType.All) {
        return Object.assign({}, state, {
          authorizedSendersRows: actionPayload.data,
        });
      }
    }
    case actions.SET_ADD_RECPT_DATA: {
      var actionPayload = action.payload;

      if (actionPayload.type === AddRecptType.Member) {
        return Object.assign({}, state, {
          additionalRecipientsMembers: actionPayload.data,
          selectedAddRecptMembers: actionPayload.data,
        });
      } else if (actionPayload.type === AddRecptType.Team) {
        return Object.assign({}, state, {
          additionalRecipientsTeams: actionPayload.data,
          selectedAddRecptTeams: actionPayload.data,
        });
      } else if (actionPayload.type === AddRecptType.NonNatca) {
        return Object.assign({}, state, {
          additionalRecipientsNonNatca: actionPayload.data,
        });
      } else if (actionPayload.type === AddRecptType.All) {
        return Object.assign({}, state, {
          additionalRecipientsRows: [
            ...state.additionalRecipientsNonNatca,
            ...state.additionalRecipientsTeams,
            ...state.additionalRecipientsMembers,
          ],
        });
      }
    }
    case actions.REMOVE_STAND_BY_AUTH_SENDERS: {
      if (state.currentEmailConfigTab == EmailConfigTabs.Subscribers) {
        return {
          ...state,
          additionalRecipientsMembers: state.additionalRecipientsMembers.filter(
            (f) => f.id !== 0
          ),

          additionalRecipientsTeams: state.additionalRecipientsTeams.filter(
            (f) => f.id !== 0
          ),
        };
      } else
        return {
          ...state,
          authorizedSendersMembers: state.authorizedSendersMembers.filter(
            (f) => f.id !== 0
          ),

          authorizedSendersTeams: state.authorizedSendersTeams.filter(
            (f) => f.id !== 0
          ),
        };
    }
    case actions.SELECT_POPUP_TEAM:
      if (state.currentEmailConfigTab == EmailConfigTabs.Subscribers) {
        return {
          ...state,
          selectedAddRecptTeams: [
            ...state.selectedAddRecptTeams,
            action.payload,
          ],
        };
      } else
        return {
          ...state,
          selectedTeams: [...state.selectedTeams, action.payload],
        };
    case actions.DESELECT_POPUP_TEAM:
      if (state.currentEmailConfigTab == EmailConfigTabs.Subscribers) {
        return {
          ...state,
          selectedAddRecptTeams: state.selectedAddRecptTeams.filter(
            (f) => f.teamId !== action.payload
          ),
        };
      } else
        return {
          ...state,
          selectedTeams: state.selectedTeams.filter(
            (f) => f.teamId !== action.payload
          ),
        };

    /////////////############Work for Authorized Senders State/Reducer##########//////////////
    case actions.SET_PRIVILEGE: {
      return {
        ...state,
        selectedPrivilige: action.payload,
      };
    }
    case actions.SET_COPY_TO_SENDER: {
      return {
        ...state,
        sendCopyToSender: action.payload,
      };
    }
    case actions.SET_REPLY_PREF: {
      return {
        ...state,
        replyPreference: action.payload,
      };
    }
    case actions.SET_MG_LIST_ALIAS:
      let isAliasChanged = false;
      if (state.mgListAddressAliasOld !== action.payload) {
        isAliasChanged = true;
      }
      return {
        ...state,
        mgListAddressAlias: action.payload,
        isAliasChanged,
      };
    case actions.SET_MG_LIST_DOMAIN:
      return {
        ...state,
        mgListDomain: action.payload,
      };
    // case actions.SET_MG_POST_LIST_DOMAIN:
    //   return {
    //     ...state,
    //     postListDomain: action.payload,
    //   };
    case actions.SET_MG_LIST_FULL_ADDRESS:
      var fullListAddress = action.payload;
      let oldAlias = state.mgListAddress.split("@")[0];
      let alias = fullListAddress ? fullListAddress.split("@")[0] : "";
      let domain = fullListAddress ? fullListAddress.split("@")[1] : "";
      return Object.assign({}, state, {
        mgListAddress: fullListAddress,
        mgListAddressAlias: alias,
        mgListDomain: domain,
        mgListAddressAliasOld: oldAlias,
      });

    case actions.SET_MG_LIST_DETAILS:
      var payload = action.payload;
      let emailListId = payload.emailListId;
      let emailEntityType = payload.emailEntityType;
      let emailEntityKey = payload.entityKey;
      let mailingListName = payload.mailingListName || "";
      let listPrivilige = payload.listPrivilige || "";
      let replyPreference = payload.replyPreference || "";
      return {
        ...state,
        emailListId: emailListId,
        emailEntityType: emailEntityType,
        entityKey: emailEntityKey,
        mailingListName: mailingListName,
        selectedPrivilige: listPrivilige,
        replyPreference: replyPreference,
      };
    // case actions.RESET_MG_LIST_DETAILS:
    //   return {
    //     ...state,
    //     emailListId: 0,
    //     emailEntityType: 0,
    //     entityKey: 0,
    //     mailingListName: "",
    //     selectedPrivilige: "readonly",
    //     replyPreference: "list",
    //     selectedMembers: [],
    //   };
    case actions.SET_EMAIL_LIST_NAME:
      return {
        ...state,
        mailingListName: action.payload || "",
      };

    case actions.REMOVE_ADDL_SUBS:
      return {};

    case actions.SET_SAVED_SEARCH_DDL_OBJ:
      return {
        ...state,
        savedSearchDllObject: action.payload,
      };
    // ####Start of Non Natca Subscribers######
    case actions.SET_NON_NATCA:
      return {
        ...state,
        selectedAddtlRecipientsNonNatca: action.payload,
      };
    case actions.ADD_NON_NATCA:
      return {
        ...state,
        additionalRecipientsNonNatca: [
          ...state.additionalRecipientsNonNatca,
          ...action.payload,
        ],
      };
    case actions.REMOVE_NON_NATCA:
      return {
        ...state,
        additionalRecipientsNonNatca: state.additionalRecipientsNonNatca.filter(
          (x) => x.address.toLowerCase() != action.payload.toLowerCase()
        ),
      };
    case actions.RESET_NON_NATCA_UI:
      return {
        ...state,
        selectedAddtlRecipientsNonNatca: [
          { name: "", firstName: "", lastName: "", address: "" },
        ],
      };
    case actions.SET_IS_EMAIL_LIST_EXISTS:
      return {
        ...state,
        isEmailListExists: action.payload,
      };
    case actions.SET_DEF_SUBS_LOADING:
      return {
        ...state,
        isLoadingDefaultSubscriber: action.payload,
      };

    case actions.REMOVE_DEFAULT_RECIPIENT:
      let { email } = action.payload;
      return {
        ...state,
        defaultRecipientMembers: state.defaultRecipientMembers.filter(
          (x) => x.address.toLowerCase() != email.toLowerCase()
        ),
      };
    case actions.UPDATE_SUB_BLOCK_FLG:
      const {
        key,
        type,
        newSubscribedValue,
        newBlockValue,
        isBlockUnBlock,
      } = action.payload;
      if (type == EmailConfigTables.AdditionalRecipientsMember) {
        const updatedMembersList = state.additionalRecipientsMembers.map(
          (member) => {
            if (member.address.toLowerCase() === key.toLowerCase()) {
              if (isBlockUnBlock) {
                if (newBlockValue) {
                  return {
                    ...member,
                    blocked: newBlockValue,
                    subscribed: false,
                  };
                } else {
                  return {
                    ...member,
                    blocked: newBlockValue,
                  };
                }
              } else {
                return {
                  ...member,
                  subscribed: newSubscribedValue,
                };
              }
            }
            return member;
          }
        );

        return {
          ...state,
          additionalRecipientsMembers: updatedMembersList,
        };
      }
      if (type == EmailConfigTables.AdditionalRecipientsTeam) {
        const updatedTeamsList = state.additionalRecipientsTeams.map((team) => {
          if (team.teamId === key) {
            if (isBlockUnBlock) {
              if (newBlockValue) {
                return {
                  ...team,
                  isblocked: newBlockValue,
                  isactive: false,
                };
              } else {
                return {
                  ...team,
                  isblocked: newBlockValue,
                };
              }
            } else {
              return {
                ...team,
                isactive: newSubscribedValue,
              };
            }
          }
          return team;
        });

        return {
          ...state,
          additionalRecipientsTeams: updatedTeamsList,
        };
      }
      if (type == EmailConfigTables.NonNatcaMembers) {
        const nonNatcaList = state.additionalRecipientsNonNatca.map((mem) => {
          if (mem.address.toLowerCase() === key.toLowerCase()) {
            if (isBlockUnBlock) {
              if (newBlockValue) {
                return {
                  ...mem,
                  subscribed: false,
                  blocked: newBlockValue,
                };
              } else {
                return {
                  ...mem,
                  blocked: newBlockValue,
                };
              }
            } else {
              return {
                ...mem,
                subscribed: newSubscribedValue,
              };
            }
          }
          return mem;
        });

        return {
          ...state,
          additionalRecipientsNonNatca: nonNatcaList,
        };
      }
      if (type == EmailConfigTables.DefaultMembers) {
        ///let c = state.defaultRecipientMembers.filter((x) => x.address === address);
        const defaultMembers = state.defaultRecipientMembers.map((mem) => {
          if (mem.address.toLowerCase() === key.toLowerCase()) {
            if (isBlockUnBlock) {
              if (newBlockValue) {
                return {
                  ...mem,
                  subscribed: false,
                  blocked: newBlockValue,
                };
              } else {
                return {
                  ...mem,
                  blocked: newBlockValue,
                };
              }
            } else {
              return {
                ...mem,
                subscribed: newSubscribedValue,
              };
            }
          }
          return mem;
        });

        const allDefaultMembers = state.allDefaultSubscribers.map((mem) => {
          if (mem.address.toLowerCase() === key.toLowerCase()) {
            if (isBlockUnBlock) {
              if (newBlockValue) {
                return {
                  ...mem,
                  subscribed: false,
                  blocked: newBlockValue,
                };
              } else {
                return {
                  ...mem,
                  blocked: newBlockValue,
                };
              }
            } else {
              return {
                ...mem,
                subscribed: newSubscribedValue,
              };
            }
          }
          return mem;
        });

        return {
          ...state,
          defaultRecipientMembers: defaultMembers,
          allDefaultSubscribers: allDefaultMembers,
        };
      } //END Block for Default Members

    default:
      return state;
  }
}
