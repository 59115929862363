import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import _ from "lodash";
import { Link } from "react-router-dom";
import overlayFactory from "react-bootstrap-table2-overlay";
import { Spinner } from "reactstrap";
import mailGunEmailActions from "../../../store/mailgunEmails/action";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import { useDispatch } from "react-redux";
import MG_EmailUpdateConfirmationModal from "../ModalPopups/MG_EmailUpdateConfirmationModal";
import {
  UpdateEmailTypeOptions,
  UpdateEmailFrequencyOptions,
  UpdateEmailFrequency,
  UpdateEmailType,
  UpdateEmailFormat,
  UpdateEmailFormatOptions,
  EMAIL_UPDATE_SUBSCRIBERS_FIELDS,
} from "../Enums/UpdateEmailEnums";
import EmailListService from "../EmailListService";

const MyExportCSV = (props) => {
  const handleClick = async () => {
    const formData = {
      recordtype: props.requestType, // Active/InAccountReview || My/Review Requests
      isExportTable: true,
      PageNo: 1,
      PageSize: 1,
      SortOrder: "id",
      activeTab: props.activeSubHeader, // Inventory Or Requests
      isAdvanceSearchApplied: props.isAdvanceSearchApplied,
      searchCriteria: props.isAdvanceSearchApplied ? props.searchCriteria : {},
      showClosed: props.showClosed,
    };
    // var response = await FEService.getDataForFEExport(formData);
    // if (!_.isEmpty(response)) {
    //   props.onExport(response.data);
    // }
  };
  return (
    <div>
      <i
        title="Export to csv"
        className="csvIconSize fas fa-file-csv"
        onClick={handleClick}
      >
        {" "}
      </i>
    </div>
  );
};

const MyExportPDF = (props) => {
  const handleClick = () => {
    _html2canvas(props);
  };
  return (
    <div>
      <i
        title="Export to pdf"
        className="csvIconSize fas fa-file-pdf pl-1"
        onClick={handleClick}
      >
        {" "}
      </i>
    </div>
  );
};
const NoDataIndication = () => (
  <div className="container h-100">
    <div className="row NoDataFoundTable">
      <h5>No Data Found!</h5>
    </div>
  </div>
);
const CaptionElement = (props) => (
  <h6 className="sub_tbl_caption">{props.header}</h6>
);
// const Empty = () => <div></div>;

const getExportFileName = (tblType, filetype) => {
  // if (tblType === FERequestsType.MyRequests) {
  //   return `${Date.now()}_MyRequestsList.${filetype}`;
  // } else if (tblType == FERequestsType.ApprovedReviewRequests) {
  //   return `${Date.now()}_ApprovedReviewRequests.${filetype}`;
  // } else if (tblType == FERequestsType.SubmittedReviewRequests) {
  //   return `${Date.now()}_SubmittedReviewRequests.${filetype}`;
  // } else if (tblType == FERequestsType.PendingEVPReviewRequests) {
  //   return `${Date.now()}_PendingReviewRequests.${filetype}`;
  // }
};
const _html2canvas = async (allprops) => {
  let filename = getExportFileName(allprops.requestType, "pdf");
  const formData = {
    recordtype: allprops.requestType, // Active/InAccountReview || My/Review Requests
    isExportTable: true,
    PageNo: 1,
    PageSize: 10,
    SortOrder: "id",
    activeTab: allprops.activeSubHeader, // Inventory Or Requests
    isAdvanceSearchApplied: allprops.isAdvanceSearchApplied,
    searchCriteria: allprops.isAdvanceSearchApplied
      ? allprops.searchCriteria
      : {},
    showClosed: allprops.showClosed,
  };
  // var response = await FEService.getDataForFEExport(formData);
  // if (!_.isEmpty(response)) {
  //   var tableBody = response.data.map((val) => {
  //     var listRow = [];
  //     listRow.push(val.userName);
  //     listRow.push(val.description);
  //     listRow.push(val.requesterName);
  //     listRow.push(val.budgetLineName);
  //     listRow.push(val.categoryName);
  //     listRow.push(val.typeName);
  //     listRow.push(val.statusName);
  //     return listRow;
  //   });
  //   const doc = new jsPDF();
  //   doc.autoTable({
  //     head: [
  //       [
  //         "USER",
  //         "DESCRIPTION",
  //         "REQUESTER",
  //         "BUDGET",
  //         "CATEGORY",
  //         "TYPE",
  //         "STAUS",
  //       ],
  //     ],
  //     body: tableBody,
  //     tableWidth: "auto",
  //     styles: { cellPadding: 0.7, fontSize: 8 },
  //     columnStyles: {
  //       0: { cellWidth: 25 },
  //       1: { cellWidth: 25 },

  //       // etc
  //     },
  //   });
  //   doc.save(filename);
  //}
};

//*************************************************************************/
//*************************Main Component*********************************//
//*************************************************************************/
const EmailUpdateSubscribersTable = (props) => {
  const { listData, loading, caption, showEmailIcon } = props;
  const [tableData, setTableData] = useState(listData);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [deleteRecordId, setDeleteRecordId] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [memberId, setMemberId] = useState(0);
  const [fullName, setFullName] = useState("");

  const dispatch = useDispatch();
  const setCurrentMemberInfo = (firstName, lastName, memberId, name) => {
    setFirstName(firstName);
    setLastName(lastName);
    setMemberId(memberId);
    setFullName(name);
  };
  const openDeleteConfirmModal = (row, id, memberId) => {
    if (id) {
      setIsConfirmModalOpen(true);
      setDeleteRecordId(id);
      setCurrentMemberInfo(row.firstName, row.lastName, row.memberId, row.name);
    } else {
      dispatch(mailGunEmailActions.removeEmailUpdateSub(memberId));
      dispatch(mailGunEmailActions.deSelectEmailUpdateMember(memberId));
    }
  };
  const confirmDeleteAction = async () => {
    // let apiRequestBody = {
    //   entityId: deleteRecordId,
    //   memberId: memberId,
    //   memberName: firstName + " " + lastName,
    // };
    var response = await EmailListService.disableEmailUpdateRecipient(
      deleteRecordId
    );
    if (response != null) {
      dispatch(mailGunEmailActions.removeEmailUpdateSub(memberId));
      dispatch(mailGunEmailActions.deSelectEmailUpdateMember(memberId));
      toggleConfirmDeleteModal();
    }
  };
  const toggleConfirmDeleteModal = () => {
    setIsConfirmModalOpen(!isConfirmModalOpen);
  };

  const showEmailLink = (cell, row) => {
    return (
      <div className="mobileActions">
        <div className="actionIcons">
          {cell}
          {showEmailIcon && (
            <Link
              to={{
                pathname: "/directory/manageFilterUpdates/",
              }}
              target="_blank"
            >
              <i
                className="fa fa-info-circle fa-md pl-1 pt-2 mr-1 cursorPointer"
                title="Details"
              ></i>
            </Link>
          )}
        </div>
      </div>
    );
  };
  const RemotePagination = () => {
    const actions = (cell, row) => {
      return (
        <div className="mobileActions">
          <div className="actionIcons">
            <Link
              to={{
                pathname: "/directory/userAction/" + row.memberId,
              }}
              target="_blank"
            >
              <i
                className="fas fa-eye fa-md pl-1 pt-2 mr-1 cursorPointer"
                title="View Member"
              ></i>
            </Link>
            <i
              title="Remove"
              className="fas fa-trash-alt mr-2 cursorPointer text-danger"
              //style={{ marginLeft: "10%" }}
              onClick={() => {
                openDeleteConfirmModal(row, row.id, row.memberId);
              }}
            />
          </div>
        </div>
      );
    };
    const [columns, setColumns] = useState([
      {
        text: "FIRST NAME",
        dataField: EMAIL_UPDATE_SUBSCRIBERS_FIELDS.FIRST_NAME,
        sort: true,
        editable: false,
      },
      {
        text: "LAST NAME",
        dataField: EMAIL_UPDATE_SUBSCRIBERS_FIELDS.LAST_NAME,
        sort: true,
        editable: false,
      },
      {
        text: "FACILITY",
        dataField: EMAIL_UPDATE_SUBSCRIBERS_FIELDS.FACILITY,
        sort: true,
        editable: false,
      },
      {
        dataField: EMAIL_UPDATE_SUBSCRIBERS_FIELDS.ID,
        sort: true,
        hidden: true,
        csvExport: false,
      },
      {
        text: "Email",
        dataField: EMAIL_UPDATE_SUBSCRIBERS_FIELDS.EMAIL,
        sort: true,
        editable: false,
        formatter: (cell, row) => {
          return showEmailLink(cell, row);
        },
        csvExport: false,
        headerStyle: (colum, colIndex) => {
          return { width: "25%" };
        },
      },
      {
        dataField: EMAIL_UPDATE_SUBSCRIBERS_FIELDS.FREQUENCY,
        text: "Update Freq.",
        // headerStyle: (colum, colIndex) => {
        //   return { width: "15%" };
        // },
        editor: {
          type: Type.SELECT,

          getOptions: (setOptions, { row, column }) => {
            console.log(`current editing row id: ${row.id}`);
            console.log(`current editing column: ${column.dataField}`);
            return UpdateEmailFrequencyOptions;
          },
        },
      },
      {
        dataField: EMAIL_UPDATE_SUBSCRIBERS_FIELDS.UPDATE_TYPE,
        text: "Update Type",
        headerStyle: (colum, colIndex) => {
          return { width: "17%" };
        },
        editor: {
          type: Type.SELECT,
          getOptions: (setOptions, { row, column }) => {
            //console.log(`current editing row id: ${row.id}`);
            //console.log(`current editing column: ${column.dataField}`);
            return UpdateEmailTypeOptions;
          },
        },
      },
      {
        text: "Format Type",
        dataField: EMAIL_UPDATE_SUBSCRIBERS_FIELDS.FORMAT_TYPE,
        editable: true,
        hidden: true, // Start with hidden
        editor: {
          type: Type.SELECT,
          getOptions: (setOptions, { row, column }) => {
            //console.log(`current editing row id: ${row.id}`);
            // console.log(`current editing column: ${column.dataField}`);
            return UpdateEmailFormatOptions;
          },
        },
      },
      {
        text: "",
        dataField: EMAIL_UPDATE_SUBSCRIBERS_FIELDS.MEMBER_ID,
        isDummyField: true,
        classes: "pl-3 pr-2 py-2",
        formatter: (cell, row) => {
          return actions(cell, row);
        },
        csvExport: false,
      },
    ]);
    // const conditionalColumn = {
    //   text: "Format Type",
    //   dataField: "formatType",
    //   editable: true,
    //   editor: {
    //     type: Type.SELECT,
    //     // options: [
    //     //   {
    //     //     value: "UpdatesOnly",
    //     //     label: "Updates Only",
    //     //   },
    //     //   {
    //     //     value: "MemberList",
    //     //     label: "Member List",
    //     //   },
    //     // ],
    //     getOptions: (setOptions, { row, column }) => {
    //       console.log(`current editing row id: ${row.id}`);
    //       console.log(`current editing column: ${column.dataField}`);
    //       return UpdateEmailFormat;
    //     },
    //   },
    //   //hidden: true, // Start with hidden
    // };
    //debugger;
    // const withAdditionalCol = [...columns(), conditionalColumn];
    // const defaultColumns = columns();
    // // Conditionally show the extra column based on the 'updateTypeOption' value
    // const shouldShow = data.some(
    //   (row) => row.updateTypeOption.toLowerCase() === "member list"
    // )
    //   ? withAdditionalCol
    //   : defaultColumns;

    // useEffect(() => {
    //   // Check if any row has 'updateTypeOption' as 'member list' and update the column visibility accordingly
    //   const shouldShowformatType = tableData.some(
    //     (row) => row.updateTypeOption.toLowerCase() === "member list"
    //   );

    //   if (shouldShowformatType) {
    //     setColumns((prevColumns) =>
    //       prevColumns.map((col) =>
    //         col.dataField === "formatType" ? { ...col, hidden: false } : col
    //       )
    //     );
    //   }
    // }, [tableData]);
    useEffect(() => {
      const shouldShow = listData.some(
        (row) => row.updateTypeOption === UpdateEmailType.MemberList.label
      );

      if (shouldShow) {
        for (let i = 0; i < columns.length; i++) {
          if (
            columns[i].dataField === EMAIL_UPDATE_SUBSCRIBERS_FIELDS.FORMAT_TYPE
          ) {
            columns[i].hidden = false;
            break;
          }
        }
      }
      setTableData(listData);
    }, [listData, tableData]);

    return (
      <>
        <div
          className="Member_Email_Update_Subs_Tbl"
          id="Member_Email_Update_Subs_Id"
          key="Member_Email_Update_Subs"
        >
          <ToolkitProvider
            keyField={"memberId"}
            data={tableData}
            //columns={updatedColumns}
            columns={columns}
          >
            {(props) => (
              <>
                <BootstrapTable
                  keyField={"memberId"}
                  loading={!_.isEmpty(tableData) && loading}
                  overlay={overlayFactory({
                    spinner: true,
                    styles: {
                      overlay: (base) => ({
                        ...base,
                        background: "rgba(40, 45, 42, 0.19)",
                      }),
                    },
                  })}
                  noDataIndication={() => {
                    return loading === false ? (
                      <NoDataIndication />
                    ) : (
                      <div className="d-flex justify-content-center p-4">
                        <Spinner />
                      </div>
                    );
                  }}
                  caption={<CaptionElement header={caption} />}
                  cellEdit={cellEditFactory({
                    mode: "click",
                    blurToSave: true,
                    afterSaveCell: (oldValue, newValue, row, column) => {
                      if (
                        column.dataField ==
                        EMAIL_UPDATE_SUBSCRIBERS_FIELDS.FREQUENCY
                      ) {
                        if (newValue == UpdateEmailFrequency.DAILY.label) {
                          row.updateFrequencyId = UpdateEmailFrequency.DAILY.id;
                        }
                        if (newValue == UpdateEmailFrequency.WEEKLY.label) {
                          row.updateFrequencyId =
                            UpdateEmailFrequency.WEEKLY.id;
                        }
                        if (newValue == UpdateEmailFrequency.MONTHLY.label) {
                          row.updateFrequencyId =
                            UpdateEmailFrequency.MONTHLY.id;
                        }
                      }
                      if (
                        column.dataField ==
                        EMAIL_UPDATE_SUBSCRIBERS_FIELDS.UPDATE_TYPE
                      ) {
                        if (newValue == UpdateEmailType.UpdatesOnly.label) {
                          row.updateTypeId = UpdateEmailType.UpdatesOnly.id;
                        }
                        //***Show the option for Format Type***//
                        if (newValue == UpdateEmailType.MemberList.label) {
                          //**Update the tableData**//
                          // const updatedData = tableData.map((r) => {
                          //   if (r.memberId === row.memberId) {
                          //     r.updateTypeId = UpdateEmailType.MemberList.id;
                          //     r.updateTypeOption = newValue;
                          //     r[
                          //       EMAIL_UPDATE_SUBSCRIBERS_FIELDS.FORMAT_TYPE_ID
                          //     ] = UpdateEmailFormat.HTML.id;
                          //     r[EMAIL_UPDATE_SUBSCRIBERS_FIELDS.FORMAT_TYPE] =
                          //       UpdateEmailFormat.HTML.label;
                          //   }
                          //   return r;
                          // });
                          row.updateTypeId = UpdateEmailType.MemberList.id;
                          row.updateTypeOption = newValue;
                          row[EMAIL_UPDATE_SUBSCRIBERS_FIELDS.FORMAT_TYPE_ID] =
                            UpdateEmailFormat.HTML.id;
                          row[EMAIL_UPDATE_SUBSCRIBERS_FIELDS.FORMAT_TYPE] =
                            UpdateEmailFormat.HTML.label;
                          setTableData(tableData);
                        }
                        if (
                          newValue == UpdateEmailType.UpdatesOnly.label &&
                          oldValue == UpdateEmailType.MemberList.label
                        ) {
                          //Reset the "Format Type"
                          row[
                            EMAIL_UPDATE_SUBSCRIBERS_FIELDS.FORMAT_TYPE_ID
                          ] = null;
                          row[EMAIL_UPDATE_SUBSCRIBERS_FIELDS.FORMAT_TYPE] = "";
                          setTableData(tableData);
                        }
                      } else if (
                        column.dataField ==
                        EMAIL_UPDATE_SUBSCRIBERS_FIELDS.FORMAT_TYPE
                      ) {
                        if (newValue == UpdateEmailFormat.HTML.label) {
                          row.formatTypeId = UpdateEmailFormat.HTML.id;
                          row.formatTypeOption = UpdateEmailFormat.HTML.label;
                        }
                        if (newValue == UpdateEmailFormat.PDF.label) {
                          row.formatTypeId = UpdateEmailFormat.PDF.id;
                          row.formatTypeOption = UpdateEmailFormat.PDF.label;
                        }
                        if (newValue == UpdateEmailFormat.CSV.label) {
                          row.formatTypeId = UpdateEmailFormat.CSV.id;
                          row.formatTypeOption = UpdateEmailFormat.CSV.label;
                        }
                      }
                      //console.log("After Saving Cell!!");
                    },
                    beforeSaveCell: (oldValue, newValue, row, column) => {
                      if (
                        newValue == "" ||
                        newValue == null ||
                        newValue == undefined
                      ) {
                        if (
                          column.dataField ==
                          EMAIL_UPDATE_SUBSCRIBERS_FIELDS.FREQUENCY
                        ) {
                          row.updateFrequencyId = UpdateEmailFrequency.DAILY.id;
                          row.updateFrequencyOption =
                            UpdateEmailFrequency.DAILY.label;
                        } else if (
                          column.dataField ==
                          EMAIL_UPDATE_SUBSCRIBERS_FIELDS.UPDATE_TYPE
                        ) {
                          row.updateTypeId = UpdateEmailType.UpdatesOnly.id;
                          row.updateTypeOption =
                            UpdateEmailType.UpdatesOnly.label;
                        }
                      }
                      //console.log("Before Saving Cell!!");
                    },
                  })}
                  {...props.baseProps}
                />
              </>
            )}
          </ToolkitProvider>
        </div>
        <MG_EmailUpdateConfirmationModal
          confirm={confirmDeleteAction}
          modal={isConfirmModalOpen}
          toggle={toggleConfirmDeleteModal}
          userFirstName={firstName}
          userLastName={lastName}
          fullName={fullName}
          classId="ManageEmailUpdateSubscribers"
        />
      </>
    );
  };

  return <>{RemotePagination()}</>;
};

EmailUpdateSubscribersTable.propTypes = {
  listData: PropTypes.array.isRequired,
};

EmailUpdateSubscribersTable.defaultProps = {
  listData: [],
};

export default EmailUpdateSubscribersTable;
