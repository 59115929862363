import React from "react";
import _ from "lodash";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
export default function MG_EmailUpdateConfirmationModal(props) {
  const { modal, toggle, userFirstName, userLastName, fullName } = props;
  let deleteMessage = () => {
    let name = "";
    if (_.isEmpty(userFirstName) || _.isEmpty(userLastName)) {
      name = "'" + fullName + "'";
    } else {
      name =
        !_.isEmpty(userFirstName) && !_.isEmpty(userLastName)
          ? "'" + userFirstName + "-" + userLastName + "'"
          : "";
    }

    return `${name} will no longer receive updates. Are you sure you want to Delete?`;
  };

  return (
    <Modal
      centered={true}
      autoFocus={true}
      isOpen={modal}
      toggle={toggle}
      backdrop="static"
      className={props.classId}
    >
      <ModalHeader toggle={toggle}>Confirm Delete</ModalHeader>
      <ModalBody>
        <div className="col-12 mb-4">
          <h5>{deleteMessage()}</h5>
        </div>{" "}
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn btn-primary w-md waves-effect waves-light"
          onClick={() => {
            props.confirm();
          }}
        >
          Delete
        </Button>{" "}
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}
