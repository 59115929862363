import React, { Component } from "react";
import axios from "../../../Shared/auth-header";
import AuthService from "../../../Auth/AuthService";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import _ from "lodash";
import actionFilter from "../../../../store/advancedfilter/action";
import savedSearchAction from "../../../../store/saveSearch/action";
import mailGunEmailActions from "../../../../store/mailgunEmails/action";
import { connect } from "react-redux";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import { toast } from "react-toastify";
import { Card, CardBody, Collapse } from "reactstrap";
import FiltersService from "./FiltersService";
import ManageUpdateEmailTable from "./ManageUpdateEmailTable";
import * as emailUpdateActions from "../../../../slices/emailUpdateSlice";
import MG_ManageFiltersUpdate from "../../../Email/ModalPopups/MG_ManageFiltersUpdate";
import EmailListService from "../../../Email/EmailListService";
const filter = createFilterOptions();
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const OWNERSHIP_ITEM = 1;
const BUCKET_ITEM = 2;
const SHARED_ITEM = 3;

export class ManageUpdateEmailList extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    memberId: AuthService.getProfile().memberData.id,
    searchId: 0,
    searchName: "",
    showListing: true,
    selectedMemberIds: [],
    selectedMembersObj: [],
  };

  // rowEvents = {
  //   onClick: (e, row, rowIndex) => {
  //     this.props.toggleEditFilterModal(row, true);
  //     this.logicSaveSearch(row);
  //   },
  // };
  //Onclick event handler from Filters listing screen
  viewEditFilter = (row) => {
    //const {
    //  location: { pathname, search },
    //} = this.props.history;
    //const searchParams = new URLSearchParams(search);
    //if (pathname == "/directory/myfilters") {
    //  // My Filters
    //  row = { ...row, owner_id: row.member_id };
    //}
    ////row = {...row, row.mem}
    //if (searchParams) {
    //  const newUrl = pathname.split("?")[0];
    //  this.props.history.replace(newUrl);
    //}
    this.props.toggleEmailUpdateSubscriberModal({
      currentRow: row,
      flag: true,
    });
    //this.logicSaveSearch(row);
  };
  deleteFilter = (row) => {
    let loggedInMember = this.state.memberId;
    let {
      search_name,
      id,
      search_type_id,
      real_search_type_id,
      owner_id, // incase of AllFilters screen
      member_id, //incase of MyFilter screen
      saved_search_id, //incase of shared filter
    } = row;
    let memberId = 0;
    let filterId = 0;
    let type = 0;
    if (real_search_type_id) {
      filterId = saved_search_id;
      memberId = member_id;
      type = real_search_type_id; //most probably Bucket or Ownership
      // 1 for Ownership, 2 for Bucket and 3 for Shared
    } else {
      filterId = id;
      type = search_type_id;
      if (owner_id && owner_id !== loggedInMember) {
        // Check if logged in member is not owner of filter
        memberId = owner_id;
      } else {
        memberId = loggedInMember; // If logged in member is owner of filter
      }
    }
    this.deleteSearch(search_name, filterId, type, memberId);
  };

  async deleteSearch(searchName, searchId, searchType, memberId) {
    if (
      window.confirm(
        `This will delete the saved search ${searchName} permanently.\r\nIf this search is shared with other users they will lose access.\r\nAre you sure?`
      )
    ) {
      let deleteUrl =
        "/api/SavedSearch/DeleteSearch?savedSearchId=" +
        searchId +
        "&searchType=" +
        searchType +
        "&memberId=" +
        memberId;
      this.showSpinner();
      try {
        const { data } = await axios.delete(deleteUrl);
        this.hideSpinner();
        if (data.status) {
          if (data.status.toLowerCase() === "success") {
            toast.success(data.message);
            const {
              location: { pathname },
            } = this.props.history;
            if (pathname == "/directory/myfilters") {
              // ****My Filters****
              this.refreshState();
            } else {
              // ****All Filters***
              const { allSystemFilters } = this.props.savedSearchState; //current filter opened
              //Remove the filter from the Redux List
              const currentFilters = allSystemFilters.filter(
                (f) => f.id !== searchId
              );
              //Will Reload New Filters Listing Screen and DropDown of Saved Searches
              this.props.setAllSystemFilters(currentFilters);
            }

            this.props.resetSavedSearch();
          } else {
            toast.error(data.message);
          }
        } else {
          toast.error("Search cannot be deleted.");
        }
      } catch (ex) {
        console.log(ex);
        toast.error("Something went wrong!");
        this.hideSpinner();
      }
    }
  }
  // logicSaveSearch = (item) => {
  //   if (
  //     item.search_type_id == BUCKET_ITEM ||
  //     (item.search_type_id == SHARED_ITEM &&
  //       item.real_search_type_id == BUCKET_ITEM)
  //   ) {
  //     //this.fetchBucketSearchCriteria(item);
  //     this.fetchSearchCriteria(item);
  //   } else {
  //     this.fetchSearchCriteria(item);
  //   }
  // };
  // fetchSearchCriteria = async (item) => {
  //   //debugger;
  //   let searchId = item.id, ///confirm is this the right id
  //     //searchTitle = item.search_name,
  //     searchTypeId = item.search_type_id,
  //     owner_member = item.owner_id;

  //   if (searchId && searchTypeId) {
  //     this.props.ResetlocalPaging();
  //     this.props.isSavedSearchSelected(true);
  //     this.props.setAdvancedSearch(false);
  //     //this.props.redirectToMembers();
  //     this.props.setSavedSearchCriteria(null);
  //     this.props.setSavedSearchDetail(null);
  //     this.setState({
  //       savedSearchId: searchId,
  //       savedSearchTypeId: searchTypeId,
  //       activeSearchId: searchId,
  //     });
  //     this.setState({ showDropdown: false });
  //     let body = {
  //       SearchId: searchId,
  //       MemberId: owner_member, //this.state.memberId,
  //       SearchType: searchTypeId,
  //     };
  //     if (item.real_search_type_id) {
  //       body.MemberId = this.state.memberId;
  //     }

  //     let shared_type_id = 0;
  //     let shared_search_id = 0;
  //     if (
  //       item.search_type_id == SHARED_ITEM &&
  //       item.real_search_type_id == BUCKET_ITEM
  //     ) {
  //       shared_type_id = item.real_search_type_id;
  //       shared_search_id = item.saved_search_id;
  //     }
  //     this.props.setSavedSearchDDLObject({
  //       ...body,
  //       savedSearchPerformed: true,
  //     });
  //     this.showSpinner();
  //     var _this = this;
  //     let response = await FiltersService.GetSavedSearchFilter(body);
  //     if (response) {
  //       this.props.setSavedSearchCriteria({
  //         savedSearchCriteriaDetail: response,
  //         searchTypeId,
  //         searchId,
  //         sharedSaveSearchTypeId: shared_type_id,
  //         sharedBucketSearchId: shared_search_id,
  //       });
  //       //delete item.search_criteria;
  //       this.props.setSavedSearchDetail(item);
  //       if (searchTypeId == BUCKET_ITEM) {
  //         this.props.isSavedBucket(true);
  //         this.props.setSavedBucketId(searchId);
  //       } else {
  //         this.props.isSavedBucket(false);
  //         this.props.setSavedBucketId(0);
  //       }
  //       $("body").trigger("click");
  //     }
  //     this.hideSpinner();
  //   }
  // };
  fetchBucketSearchCriteria(item) {
    var items = JSON.parse(localStorage.getItem("BucketSearch"));
    if (items !== null && items.length > 0) {
      this.setState({ confirmBucketSearch: item });
      window.$("#CLBSModal").modal("show");
    } else {
      this.fetchSearchCriteria(item);
    }
  }
  handleClose = () => {
    this.props.toggleEmailUpdateSubscriberModal({}, false);
  };
  refreshState = async () => {
    this.showSpinner();
    let data = await FiltersService.getMemberSearches(this.state.memberId);
    const { ownershipItems, bucketSearchItems, sharedSearchItems } = data;
    const {
      SavedSearchDetail,
      RefreshState,
      RefreshSelectId,
    } = this.props.savedSearchState;
    const selectId = RefreshSelectId;
    const searchType = SavedSearchDetail
      ? SavedSearchDetail.search_type_id
      : null;

    // const findSearchCriteria = (searchItems) => {
    //   const currentObj = searchItems.find((x) => x.id === selectId);
    //   if (currentObj) {
    //     this.fetchSearchCriteria(currentObj);
    //   }
    // };

    let selectedSearchItem = null;

    if (Array.isArray(ownershipItems) && searchType === OWNERSHIP_ITEM) {
      selectedSearchItem = ownershipItems.find(
        (x) => x.id === SavedSearchDetail.id
      );
    } else if (Array.isArray(bucketSearchItems) && searchType === BUCKET_ITEM) {
      selectedSearchItem = bucketSearchItems.find(
        (x) => x.id === SavedSearchDetail.id
      );
    } else if (Array.isArray(sharedSearchItems) && searchType === SHARED_ITEM) {
      selectedSearchItem = sharedSearchItems.find(
        (x) => x.id === SavedSearchDetail.id
      );
    }

    if (selectedSearchItem) {
      //delete selectedSearchItem.search_criteria;
      this.props.setSavedSearchDetail(selectedSearchItem);
    }

    if (RefreshState) {
      let data2 = ownershipItems.find((x) => x.id === selectId);
      if (!data2) {
        data2 = bucketSearchItems.find((x) => x.id === selectId);
      }
      if (!data2) {
        data2 = sharedSearchItems.find((x) => x.id === selectId);
      }
      if (data2) {
        this.fetchSearchCriteria(data2);
      }
    }

    const allFilters = [
      ...ownershipItems,
      ...bucketSearchItems,
      ...sharedSearchItems,
    ];
    let truncatedList = allFilters.map((obj) => {
      // Create a copy of the object without the "search_criteria" property
      const { search_criteria, ...rest } = obj;
      return rest;
    });
    this.props.setMemberFilters(truncatedList);

    //const pinnedOnly = allFilters.filter((item) => item.is_pinned === 1);

    this.setState({
      ownershipItems,
      bucketSearchItems,
      sharedSearchItems,
      //allFilters: pinnedOnly,
    });

    this.hideSpinner();
  };
  reloadFiltersTable = async () => {
    // const { showListing, memberId } = this.state;
    // const { loadAllFilters } = this.props;
    // if (showListing && !loadAllFilters) {
    //   this.refreshState(memberId);
    // } else if (loadAllFilters) {
    //   await this.props.setAllFilterLoading(true);
    //   let data = await FiltersService.getAllSystemFilters();
    //   this.props.setAllSystemFilters(data);
    //   await this.props.setAllFilterLoading(false);
    //}
    this.props.getAllEmailUpdateSavedSearches();
  };
  onChangeSubscriber = (value) => {
    const selectedIds = value.map((val) => val.id);
    this.setState({
      selectedMemberIds: selectedIds,
      selectedMembersObj: value,
    });
    const { updateSubscribersList } = this.props.emailUpdateState;
    if (selectedIds.length != 0) {
      const filtersMembers = updateSubscribersList.filter((f) =>
        selectedIds.includes(f.updateSubscriberId)
      );
      this.props.setFilteredSubscribers(filtersMembers);
    } else {
      this.props.setFilteredSubscribers(updateSubscribersList);
    }
  };
  toggleSubscribeStatus = async (id) => {
    var response = await EmailListService.toggleFilterUpdateFlag(id);
    if (response != null) {
      this.props.toggleFilterUpdateFlag({
        id,
      });
    }
  };
  render() {
    const {
      filteredUpdateSubscribersList,
      updateSubscribersList,
      isFilterUpdateModalOpened,
      status,
      currentEditFilterUpdateRow: { id = 0, filterName = "" } = {},
    } = this.props.emailUpdateState;

    if (status === "loading") {
      return <div>Loading...</div>;
    }

    if (status === "failed") {
      return <div>Error loading users</div>;
    }
    return (
      <>
        <div className="w-100 mx-auto">
          <br />
          <Card>
            <div className="row mx-0 align-items-center">
              <div className="col-sm-12">
                <h4
                  className="card-header cursorPointer page-title-search"
                  onClick={() => {
                    this.setState(
                      {
                        showListing: !this.state.showListing,
                      },
                      async () => {
                        this.reloadFiltersTable();
                      }
                    );
                  }}
                >
                  Update Email List
                </h4>
              </div>
            </div>
            <div className="row ml-4 pb-2">
              <div className="col-4">
                <Autocomplete
                  loading={status === "loading"}
                  multiple
                  limitTags={2}
                  id="subscriberFilter"
                  options={Array.from(
                    new Map(
                      updateSubscribersList.map((x) => [
                        x.updateSubscriberId,
                        x,
                      ])
                    ).values()
                  ).map((x) => ({
                    id: x.updateSubscriberId,
                    member: x.updateSubscriber,
                    checked: false,
                  }))}
                  size="small"
                  disableCloseOnSelect
                  //filterOptions={(options, state) => options}
                  autoHighlight={true}
                  getOptionLabel={(option) => {
                    if (option != undefined) {
                      return option.member;
                    } else {
                      return "";
                    }
                  }}
                  getOptionSelected={(option, value) => option.id === value.id}
                  onChange={(e, value, reason, details) => {
                    this.onChangeSubscriber(value);
                  }}
                  // For searching options inside the client's array
                  filterOptions={(options, params) => {
                    const inputValue = params.inputValue || "";
                    if (inputValue !== "") {
                      //when there is no space in search text
                      if (/^[a-zA-Z-]*$/.test(inputValue)) {
                        //if it is alphabets
                        let result = options.filter((val, ind, arr) => {
                          return val.member
                            .toLowerCase()
                            .includes(inputValue.toLowerCase());
                        });
                        return result;
                      } else if (!isNaN(inputValue) && isFinite(inputValue)) {
                        return [];
                      }
                    }
                    const filtered = filter(options, params);

                    return filtered;
                  }}
                  value={this.state.selectedMembersObj}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={this.state.selectedMemberIds.some(
                          (s) => s.id == option.id
                        )}
                      />
                      {option.member}
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Members"
                      placeholder={
                        this.state.selectedMemberIds.length == 0
                          ? `Select Member`
                          : ""
                      }
                    />
                  )}
                />{" "}
              </div>
            </div>
            <CardBody className="pt-0">
              <Collapse
                className="filtersCardCollapse"
                isOpen={this.state.showListing}
              >
                <div className="row mx-0">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12">
                        <ManageUpdateEmailTable
                          listData={filteredUpdateSubscribersList}
                          reloadFilters={this.reloadFilters}
                          isLoading={status === "loading"}
                          viewFilter={this.viewEditFilter}
                          deleteFilter={this.deleteFilter}
                          reloadFiltersTable={this.reloadFiltersTable}
                          toggleSubscribeStatus={this.toggleSubscribeStatus}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Collapse>
            </CardBody>
          </Card>
        </div>
       

        {/* // Modal PopUp which shows list of Filter Update Subscribers // */}
        <MG_ManageFiltersUpdate
          handleClose={this.handleClose}
          showModal={isFilterUpdateModalOpened}
          savedSearchTitle={filterName}
          savedSearchId={id}
        />
      </>
    );
  }
  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.filterId != this.state.filterId) {
  //     this.showEmailConfigModal();
  //   }
  // }

  componentDidMount = async () => {
    this.props.getAllEmailUpdateSavedSearches();
    //   let savedSearchId = this.props.SavedSearch.SaveSearchId; //this.props.location; // if (this.props && this.props.location) {
    //   if (
    //     this.props.filterState.isAdvancedFilterSelected === true &&
    //     savedSearchId &&
    //     savedSearchId > 0
    //   ) {
    //     this.fetchSearchCriteria(this.props.SavedSearch.SavedSearchDetail);
    //   }
    // }
    ///this.props.history.push("/directory/members");
    // this.props.setfilterActionClick(false);
    // const {
    //   allSystemFilters,
    //   isLoadingAllFilters,
    // } = this.props.savedSearchState;
    // const { memberId } = this.state;
    // const { loadAllFilters } = this.props;

    // await this.props.setAllFilterLoading(true);
    // let data = await FiltersService.getAllSystemFilters();
    // this.props.setAllSystemFilters(data);
    // await this.props.setAllFilterLoading(false);
    //console.log("mount for list");
  };
  // showEmailConfigModal = () => {
  //   const { location } = this.props;
  //   const searchParams = new URLSearchParams(location.search);
  //   const filterId = searchParams.get("showEmailConfigModal");
  //   const permissionFailed = location.state
  //     ? location.state.permissionFailed
  //     : false;
  //   if (permissionFailed) {
  //     toast.warn("Permission to access this route was denied.");
  //   }
  //   const { loadAllFilters } = this.props;
  //   // Check if the query parameter showEmailConfig is true
  //   if (filterId) {
  //     this.setState({ filterId });
  //     let allFilters = [];
  //     if (loadAllFilters) {
  //       allFilters = this.props.savedSearchState.allSystemFilters;
  //     } else {
  //       allFilters = this.props.savedSearchState.loggedInUserFilters;
  //     }
  //     let row = allFilters.find((f) => {
  //       if (
  //         f.search_type_id == SavedSearchTypes.OWNERSHIP_ITEM ||
  //         f.search_type_id == SavedSearchTypes.BUCKET_ITEM
  //       ) {
  //         return f.id == filterId;
  //       } else {
  //         return f.saved_search_id == filterId; // SavedSearchTypes.SHARED_ITEM
  //       }
  //     });
  //     if (row) {
  //       this.viewEditFilter(row);
  //     }
  //   }
  // };
  showSpinner = () => {
    $(".loading").show();
  };

  hideSpinner = () => {
    $(".loading").hide();
  };
}

export default withRouter(
  connect(
    (state) => ({
      filterState: state.advancedFilter,
      savedSearchState: state.saveSearch,
      melstate: state.mailGunEmail,
      facilityDashboardState: state.facilityDashboard,
      emailUpdateState: state.emailUpdate,
    }),
    {
      ...actionFilter,
      ...savedSearchAction,
      ...mailGunEmailActions,
      ...emailUpdateActions,
    }
  )(ManageUpdateEmailList)
);
